import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios'
import { BsCheckCircleFill } from 'react-icons/bs'
import Cookies from 'universal-cookie'


const Address = ({ currentScreen, updateShippingCost, updateTaxes, updateDiscount, updateTotal }) => {
    const cookies = new Cookies()
    const navigate = useNavigate();
    const location = useLocation();
    const [cartData, setCartData] = useState(location?.state ? location?.state : [])
    const auth_token = cookies?.get("auth_token")
    const [open, setOpen] = useState(true)
    const [inputId, setId] = useState(1)
    const [showAddressForm, setShowAddressForm] = useState(false)
    const [addresses, setAddresses] = useState([])
    const [selectedAddress, setSelectedAddress] = useState({})
    const taxes = 1000
    const shipping = 500
    const subtotal = cartData?.reduce((total, item) => total + (item?.price * item?.qty), 0)*80;
    const orderTotal = taxes + shipping + subtotal
    const address_headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth_token}`
    }
    const [firstName , setFirstName] = useState('');
    const [lastName , setLastName] = useState('');
    const [address , setAddress] = useState('');
    const [city , setCity] = useState('');
    const [country , setCountry] = useState('');
    const [state , setState] = useState('');
    const [postcode, setPostcode] = useState('');
    const [phone, setPhone] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [isDefault, setIsDefault] = useState(false)
    const [userData, setUserData] = useState()
    const [loading, setLoading] = useState(false)
    const [selectedShipping, setSelectedShipping] = useState('freeshipping')
    const [billingAddress, setBillingAddress] = useState({})
    

    //******Function to Fetch User Addresses ******//
    const fetchAddresses = () => {
        setLoading(true)
        const url = `${process.env.REACT_APP_API_URL}/rest/V1/customers/me`
        axios.get(url, { headers: address_headers })
        .then((response) => {
            console.log("response from address api to fetch address", response?.data)  
            setAddresses(response?.data?.addresses)
            setUserData(response?.data)
            setUserEmail(response?.data?.email)
            console.log("Log before setting selected address: ", response?.data?.addresses?.find((c) => c?.default_shipping === true))
            setSelectedAddress(response?.data?.addresses?.find((c) => c?.default_shipping === true))
            console.log(
              "billing address from address section: ",
              response?.data?.addresses?.find(
                (c) => c?.default_shipping === true
              )
            );
            setBillingAddress(response?.data?.addresses?.find((c)=> c?.default_billing === true))
            setLoading(false)
        })
        .catch((err) => {
            console.log('Error while fetching user address: ', err)
            setLoading(false)
        })
    }
    
    //******Function to Add New Address to User Addresses ******//
    
    const addNewAddress = () => {
        const url = `${process.env.REACT_APP_API_URL}/rest/V1/customers/me`;
        const payload = {
            "customer": {
                "email": userEmail,
                "firstname": userData?.firstname,
                "lastname": userData?.lastname,
                "website_id": 1,
                "addresses": [ ...addresses,
                    {
                        "customer_id": userData?.id,
                        "region": {
                            "region_code": "TX",
                            "region": state,
                            "region_id": 57
                        },
                        "country_id": "US",
                        "street": [
                            address
                        ],
                        "telephone": phone,
                        "postcode": "78701",
                        "city": city,
                        "firstname": firstName,
                        "lastname": lastName,
                        "default_shipping": addresses?.length > 0 ? isDefault: true,
                        "default_billing": addresses?.length > 0 ? (isDefault ? true : false) : true
                    }
                ]
            }
        }
        const req_payload = JSON.stringify(payload)
        axios.put(url, req_payload, { headers: address_headers })
        .then((response) => {
            setAddresses(response?.data?.addresses)
            setSelectedAddress(response?.data?.addresses?.find((c) => c?.default_shipping === true))
            resetState()
            console.log("response from adding address", response?.data)
        })
        .catch((err) => {
            console.log("error while adding address: ", err)    
        })
    }

    const submitShippingInfo = () => {
        const req_url = `${process.env.REACT_APP_API_URL}/rest/V1/carts/mine/shipping-information`;
        console.log(`values before payload shipping: region: ${selectedAddress?.region}, country_id: ${selectedAddress?.country_id} and street: ${selectedAddress?.street}`)
        const payload = {
          addressInformation: {
            shippingAddress: {
              region: selectedAddress?.region?.region,
              region_id: Number(selectedAddress?.region?.region_id),
              country_id: selectedAddress?.country_id,
              street: selectedAddress?.street,
              postcode: selectedAddress?.postcode,
              city: selectedAddress?.city,
              telephone: selectedAddress?.telephone,
              firstname: selectedAddress?.firstname,
              lastname: selectedAddress?.lastname,
              email: userEmail,
            },
            billingAddress: {
              region: billingAddress?.region?.region,
              region_id: Number(billingAddress?.region?.region_id),
              country_id: billingAddress?.country_id,
              street: billingAddress?.street,
              postcode: billingAddress?.postcode,
              city: billingAddress?.city,
              telephone: billingAddress?.telephone,
              firstname: billingAddress?.firstname,
              lastname: billingAddress?.lastname,
              email: userEmail,
            },
            shipping_method_code: selectedShipping,
            shipping_carrier_code: selectedShipping,
          },
        };
        const req_payload = JSON.stringify(payload)
        axios
          .post(req_url, payload, { headers: address_headers })
          .then((response) => {
            console.log("shipping info submitted: ", response?.data);
            localStorage?.setItem("shippingInfoSubmitted", true);
            updateShippingCost(response?.data?.totals?.base_shipping_incl_tax);
            updateTaxes(response?.data?.totals?.tax_amount);
            updateDiscount(response?.data?.totals?.discount_amount);
            updateTotal(response?.data?.totals?.grand_total);
          })
          .catch((err) => {
            console.log("Error with shipping: ", err?.response?.data);
            localStorage?.setItem("shippingInfoSubmitted", false);
          });
    }

    const handleContinue = () => {
        submitShippingInfo();
        setShowAddressForm(false)
        currentScreen('payment')
    }




    const resetState = () => {
        setFirstName("")
        setLastName("")
        setAddress("")
        setCity("")
        setCountry("")
        setState("")
        setPostcode("")
        setPhone("")
        setIsDefault(false)
    }

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
        fetchAddresses();
    }, [])

    useEffect(() => {
        console.log(`Checkbox is checked : ${isDefault}`)
    },[isDefault])
        
    // useEffect(() => {
    //     sessionStorage?.setItem('sa', JSON.stringify(selectedAddress))
    // }, [selectedAddress])

  return (
      <div className='w-full font-outfit flex flex-col items-start justify-start gap-7'>    
          <div className='w-full hidden lg:flex gap-3 items-center justify-center font-medium '>
              <div className='flex gap-3 items-center'>
                  <p className='rounded-full bg-black text-white p-2 w-6 h-6 flex items-center justify-center text-sm'>1</p>
                  <p>Information</p>
              </div>
              <hr className='h-0.5 w-[60px] border-t-0 bg-black opacity-100 dark:opacity-50'/>
              <div className='flex gap-3 items-center'>
                  <p className='rounded-full bg-black text-white p-2 w-6 h-6 flex items-center justify-center  text-sm'>2</p>
                  <p>Payment</p>
              </div>
              <hr className='h-0.5 w-[60px] border-t-0 bg-black opacity-100 dark:opacity-50'/>
              <div className='flex gap-3 items-center'>
                  <p className='rounded-full bg-black text-white p-2 w-6 h-6 flex items-center justify-center  text-sm'>3</p>
                  <p>Order Placed</p>
              </div>
          </div>
          {addresses?.length !== 0 &&
            <div className="flex justify-evenly items-start w-full gap-3">
                <p className="w-1/4 font-medium flex justify-center">Delivery Address</p>
                {
                    <p className="w-2/4 capitalize text-center">
                        {selectedAddress?.firstname}{" "}{selectedAddress?.lastname},
                        {" "}{selectedAddress?.street}
                        {selectedAddress?.address},
                        {" "}{selectedAddress?.city},
                        {" "}{selectedAddress?.region?.region},
                        {" "}{selectedAddress?.country_id},
                        {"  "}{selectedAddress?.postcode}
                    </p>


                }
                  {open ? <button className="w-1/4 cursor-pointer hover:underline" onClick={() => setOpen(!open)}>Change</button> :
                      <button className="w-1/4   cursor-pointer hover:underline" onClick={() => {
                          setOpen(!open)
                          setShowAddressForm(false)
                      }}>
                          Close
                      </button>}
            </div>
          }
          {
                    !open && (
                        <div className="border rounded p-4 w-full flex flex-col gap-8 items-start justify-start">
                            <p className="text-lg">ADDRESSES</p>
                            <div className="w-full flex flex-col gap-5 items-start justify-start">
                            {
                                addresses?.map((address) => {
                                    return (
                                        <div className=" flex gap-3 w-full justify-start items-center py-2 cursor-pointer bg-[#F0F2F6] rounded-md" key={address?.id} onClick={()=>setSelectedAddress(address)}>
                                            <div className="flex p-3 flex-col w-4/5">
                                                <p className="text-lg font-medium capitalize">{address?.firstname}{" "}{address?.lastname}</p>
                                                <div>
                                                    <p className=" capitalize">{address?.street}, {address?.city},<br />
                                                        {address?.region?.region}, {" "}{address?.country_id},{" "}{address.postcode}</p>
                                                </div>
                                            </div>
                                            <div className="w-1/5 flex h-full justify-end items-start px-6">
                                                {selectedAddress?.id === address?.id && <BsCheckCircleFill size={26}/>}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            </div>
                            <div className="w-full flex justify-between">
                                <button className="font-sm text-white bg-black cursor-pointer text-sm px-4 py-2 flex justify-center items-center rounded-md" onClick={()=>{setShowAddressForm(!showAddressForm)}}>ADD ADDRESS</button>
                          <button className="font-sm text-white bg-black cursor-pointer rounded-md text-sm px-4 py-2" onClick={() => {
                              setShowAddressForm(false)
                              setOpen(!open)

                          }}>SAVE</button>
                            </div>
                        </div>
                    )
          }
            {((showAddressForm || addresses?.length === 0) && loading === false) && <div className="w-full">
                    <form onSubmit={(e) => {
                         e.preventDefault()
                        addNewAddress()
                    }}>
                        <div className="flex items-center py-4 ">
                            
                            <span className="text-base text-[#2D2D2D] font-medium">ADDRESS FORM</span>
                        </div>
                        <div className="flex justify-between gap-4">
                            <div className="w-1/2">
                                <label className="text-[#767676] text-base">First Name</label>
                                <input className="w-full px-3 py-2 border-[1px] border-[#C7C7C7]
                                     focus:outline-none" type="text" placeholder="John"
                                    name="firstName" value={firstName} onChange={(e)=>{setFirstName(e?.target.value)}} required/>
                            </div>
                            <div className="w-1/2">
                                <label className="text-[#767676] text-base">Last Name</label>
                                <input className="w-full px-3 py-2 border-[1px] border-[#C7C7C7] 
                                     focus:outline-none" type="text" placeholder="Doe"
                                    name="lastName" value={lastName} onChange={(e)=>{setLastName(e?.target.value)}} required/>
                            </div>
                        </div>
                        <div className="mt-3">
                            <label className="text-[#767676] text-base">Address</label>
                            <input className="w-full px-3 py-2 border-[1px] border-[#C7C7C7] focus:outline-none" type="text"
                                placeholder="60 Sunrise Dr, Lebanon, KY, 40033" required
                                name="address" value={address} onChange={(e)=>{setAddress(e?.target.value)}} 
                            />
                        </div>
                        <div className="flex justify-start items-center gap-4 mt-4">
                            <div className="col-span-2 w-1/3">
                                <label className="text-[#767676] text-base">City</label>
                                <input className="w-full px-3 py-2 border-[1px] border-[#C7C7C7] focus:outline-none"
                                    type="text" placeholder="Your City" required
                                    name="city" value={city} onChange={(e)=>{setCity(e?.target.value)}} />
                            </div>
                            <div className="flex gap-4 w-1/3">
                            <div className="w-full flex flex-col">
                                <label className="text-[#767676] text-base">State/Province</label>
                                <input className="w-full px-3 py-2 border-[1px] border-[#C7C7C7] focus:outline-none"
                                    type="text" placeholder="New York" required
                                    name="province" value={state} onChange={(e)=>{setState(e?.target.value)}} />
                            </div>
                        </div>
                            <div className="w-1/3">
                                <label className="text-[#767676] text-base">Country</label>
                                <select className="w-full px-1 py-2 border-[1px]
                                 border-[#C7C7C7] focus:outline-none"
                                    name="country" value={country} onChange={(e)=>{setCountry(e?.target.value)}} required
                                >
                                    <option value="US">United States</option>
                                    <option value="IN">India</option>
                                    <option value="CN">China</option>
                                </select>
                            </div>
                        </div>
                        <div className="flex justify-between items-center gap-4 mt-4">
                            <div className="w-1/2">
                                <label className="text-[#767676] text-base">Phone</label>
                                <input className="w-full px-3 py-2 border-[1px] border-[#C7C7C7] focus:outline-none"
                                    type="text" placeholder="+910000000000" required
                                    name="zipcode" value={phone} onChange={(e)=>{setPhone(e?.target.value)}} 
                                />
                            </div>
                            <div className="w-1/2">
                                <label className="text-[#767676] text-base">Postal/Zipcode</label>
                                <input className="w-full px-3 py-2 border-[1px] border-[#C7C7C7] focus:outline-none"
                                    type="text" placeholder="10001" required
                                    name="zipcode" value={postcode} onChange={(e)=>{setPostcode(e?.target.value)}}
                                />
                        </div>
                        </div>

                        <div className="mt-3">
                            <input className="bg-gray-500" id="default-check" type="checkbox" name="DefaultAddress" value={isDefault} onChange={(e)=>setIsDefault(e?.target?.checked)} required/>
                            <label className="text-base font-medium" htmlFor="DefaultAddress"> Set as a default address</label>
                        </div>
                        <div className="mt-3">
                            <input className="bg-gray-500" type="checkbox" name="DefaultAddress" value="Address" required/>
                            <label className="text-base font-medium" htmlFor="DefaultAddress"> Privacy Policy Statement (consent)</label>
                        </div>
                        <div className="flex justify-start gap-4 mt-7">
                            {addresses?.length !== 0 && <button className='w-[90px]  py-3 border rounded-md bg-white text-black  text-sm font-semibold border-gray-400' onClick={()=>{setShowAddressForm(!showAddressForm)}}>CANCEL</button>}
                            <button type="submit" className=' w-[90px]  py-3 border rounded-md bg-black text-white  text-sm font-semibold border-gray-400'>SUBMIT</button>
                        </div>
              </form>
              
              
          </div>
          }
          <div className='shipping-container w-full flex flex-col gap-4 mt-8'>
                  <p className='uppercase font-semibold'>AVAILABLE SHIPPING METHODS</p>
              <div className='flex items-center justify-between p-4 gap-6 rounded-md bg-[#F0F2F6] cursor-pointer' onClick={() => {
                  setSelectedShipping('freeshipping')
              }}>
                      <div>
                          <p className='font-medium'>Standard Delivery</p>
                      <p className='text-gray-500 text-sm'>Delivery: 7-8 Business Days (Free)</p>
                      </div>
                      <div className='flex gap-3'>
                          {/* <p className='font-medium'>FREE</p> */}
                          <input type="radio" name="delivery-checkbox" id="fedex" checked={selectedShipping === "freeshipping"} onChange={()=>console.log("shipping method changed")} className='accent-black'/>
                      </div>
                  </div>
              <div className='flex items-center justify-between p-4 gap-6 rounded-md bg-[#F0F2F6] cursor-pointer' onClick={() => {
                  setSelectedShipping('flatrate')
              }}>
                      <div>
                          <p className='font-medium'>Express Delivery</p>
                          <p className='text-gray-500 text-sm'>One-day delivery. Get it delivered by tomorrow (Charge will be calculated)</p>
                      </div>
                      <div className='flex gap-3'>
                          {/* <p className='font-medium'>CHARGEABLE</p> */}
                          <input type="radio" name="delivery-checkbox" id="fedex" className='accent-black' checked={selectedShipping === "flatrate"} onChange={()=>console.log("shipping method changed")}/>
                      </div>
                  </div>
              </div>
            <div className='p-3 w-full flex justify-between items-center'>
                <button className='font-sm text-black border-black border rounded-md cursor-pointer text-sm px-4 py-2 flex justify-center items-center' onClick={() => {
                  navigate('/cart')
                }}>BACK TO CART</button>
                <button className='font-sm text-white rounded-md bg-black cursor-pointer text-sm px-4 py-2 flex justify-center items-center' onClick={() => {
                  handleContinue()
                }}>CONTINUE</button>
          </div>
    </div>
  )
}

export default Address