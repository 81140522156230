import { RudderAnalytics } from "@rudderstack/analytics-js";

const rudderAnalytics = new RudderAnalytics();
rudderAnalytics.load(
  "2jJRXB8ynWqZ2gZpYt91ETIbWnO",
  "https://altumindglqnuo.dataplane.rudderstack.com",
  {}
);

export default rudderAnalytics;
