import { AiOutlineArrowRight } from 'react-icons/ai'
import { BsTwitter, BsFacebook, BsPinterest, BsInstagram, BsSnapchat, BsYoutube } from 'react-icons/bs'
import {FaCcVisa, FaCcMastercard, FaCcPaypal, FaGooglePay} from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'


const Footer = () => {
  const navigate = useNavigate()
    return (
      <div className="bg-[#F5F5F5] mt-5 md:px-12 p-3 font-outfit">
        <div className="flex md:flex-row flex-col gap-4 justify-between">
          <div className="Logo-Box">
            <h3 className="font-bold cursor-pointer" onClick={()=>navigate("/")}>LOGO</h3>
          </div>
          {/* <div className="Explore-Box">
            <h4 className="text-lg pb-3 font-semibold  ">Explore</h4>
            <ul className="font-normal   text-base">
              <li className="py-1">Home</li>
              <li className="py-1">Categories</li>
              <li className="py-1">Blog</li>
              <li className="py-1">Contact</li>
            </ul>
          </div> */}
          <div className="Quicklinks-Box">
            <h4 className="text-lg pb-3 font-semibold  ">
              Quick Links
            </h4>
            <ul className="text-base">
              <li className="p-1 cursor-pointer hover:underline" onClick={()=>navigate("/account")}>My Account</li>
              <li className="p-1 cursor-pointer hover:underline" onClick={()=>navigate("/orders")}>Order History</li>
              <li className="p-1 cursor-pointer hover:underline" onClick={()=>navigate("/wishlist")}>Wishlist</li>
            </ul>
          </div>
          <div className="Connect-Box flex flex-col gap-3">
            <h4 className="text-lg font-semibold">Connect With Us</h4>
            <h5 className="text-base font-normal  ">
              Please enter your email
            </h5>
            <div className="border border-[#C6CC6C6] px-1 w-[200px] flex justify-between rounded-md">
              <input
                className="p-2.5 bg-transparent border-none outline-none w-4/5"
                type="email"
                placeholder="Email Address"
              />
              <button
                className="border-none p-2 outline-none w-1/5"
                type="submit"
              >
                <AiOutlineArrowRight className="cursor-pointer"/>
              </button>
            </div>
            <div className="flex my-3 py-1 gap-4 items-center">
                <BsTwitter color="black" className="cursor-pointer" size={22} />
                <BsFacebook color="black" className="cursor-pointer" size={22} />
                <BsPinterest color="black" className="cursor-pointer" size={22} />
                <BsInstagram color="black" className="cursor-pointer" size={22} />
                <BsSnapchat color="black" className="cursor-pointer" size={22} />
                <BsYoutube color="black" className="cursor-pointer" size={25} />
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center pt-5 px-3 border-t-[1px] border-[#C6CC6C6] ">
            <div className="flex gap-4 items-center">
                <FaCcVisa color="black" className="cursor-pointer" size={29} />
                <FaCcMastercard color="black" className="cursor-pointer" size={29} />
                <FaCcPaypal color="black" className="cursor-pointer" size={29} />
                <FaGooglePay color="black" className="cursor-pointer" size={29} />
          </div>
          <p className="font-normal text-sm   py-3">
            © 2023 . All Rights Reserved. Terms, Privacy & Accessibility.{" "}
          </p>
        </div>
      </div>
    );
}

export default Footer;