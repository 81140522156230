import React, { useEffect, useState } from 'react'

const ReviewCard = ({ reviewData }) => {
    const [randomColor, setRandomColor] = useState("#28B6CD");
    const colors = [
      "#28B6CD",
      "#C0573B",
      "#EFA40D",
      "#9F5708",
      "#091728",
      "#2A4054",
    ];

    const selectColor = () => {
        const randomIndex = Math.floor(Math.random() * colors?.length);
        setRandomColor(colors[randomIndex])
        console.log("random value: ", Math.floor(Math.random() * 6));
    }

    const divStyle = {
      backgroundColor: randomColor,
    };

    useEffect(() => {
        selectColor()
    },[])
  return (
    <div
      key={reviewData?.id + reviewData?.title}
      className="w-full flex flex-col gap-3 py-4"
    >
      <div className="flex justify-between">
        <div className="flex gap-2 items-center justify-start">
          <div
            className="w-[42px] h-[42px] rounded-full flex justify-center items-center"
            style={divStyle}
          >
            <p className="text-white font-medium text-lg">
              {reviewData?.nickname?.[0]}
            </p>
          </div>
          <p className="flex flex-col">
            <span className="text-md">{reviewData?.nickname}</span>
            <span className="text-gray-400 text-xs">
              {reviewData?.created_at}
            </span>
          </p>
        </div>
        <div className="flex items-center">
          <p>
            <span>{reviewData?.ratings[0]?.value}</span>
            <span>⭐</span>
          </p>
        </div>
      </div>
      <div>
        <p className="font-medium text-gray-800">{reviewData?.title}</p>
        {/* <span className="text-gray-400 text-xs">{reviewData?.created_at}</span> */}
      </div>
      <div>
        <p className="text-gray-600">{reviewData?.detail}</p>
        {/* <span className="text-gray-400 text-xs">{reviewData?.created_at}</span> */}
      </div>
    </div>
  );
}

export default ReviewCard