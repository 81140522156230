import { AiOutlineHeart } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
const SearchProductCard = ({ data }) => {
    const navigate = useNavigate()
    return (
        <div key={data?.id} className="flex flex-col justify-start items-start gap-2 border-2 rounded-md hover:shadow-xl p-2 md:w-[230px] w-[150px]"
            onClick={() => navigate(`/product/${data?.sku}/${data?.name?.replace(/ /g, "-")}`, { state: data })}
        >
            <div className='w-full flex justify-center items-center' >
                <img src={`${process.env.REACT_APP_API_URL}/media/catalog/product` + data?.media_gallery_entries?.[0]?.file} alt="prod-img" width="100%" height="100%" className="cursor-pointer object-cover  h-[200px] md:w-40 w-[130px]" />
                <button class="absolute top-1 right-1" > <AiOutlineHeart size={25} /></button>
            </div>

            <div className="flex flex-col items-start justify-center md:px-3 px-1 py-1 gap-3 w-full">
                <p className="md:text-lg text-sm font-medium w-full truncate font-lato">{data?.name}</p>
                <div className="w-full ">
                    {data?.price && <p className="font-medium md:text-base text-sm">${data?.price}.00</p>}
                </div>
            </div>

        </div>
    )
}

export default SearchProductCard