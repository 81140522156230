import React, {useEffect, useState} from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
// import { BsArrowLeft } from 'react-icons/bs'
// import { IoShareSocial } from 'react-icons/io5'
import Accordion from '../../components/Accordion/Accordion'
import {BiStar, BiSolidShareAlt} from 'react-icons/bi';
// import {ImFacebook, ImTwitter} from 'react-icons/im'
// import { FaPinterestP } from 'react-icons/fa';
import axios from 'axios'
import { CartState } from '../../context/Context'
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'universal-cookie'
import StarRating from '../../components/StarRating/StarRating';
import OAuth from 'oauth-1.0a';
import { HmacSHA256, enc } from 'crypto-js';
import Loader from '../../components/Loader/Loader';
import ReviewCard from '../../components/ReviewCard/ReviewCard';
import rudderAnalytics from '../../rudderstack';
import usePageDurationTracker from '../../components/Hooks/usePageDurationTracker';

const ProductPage = () => {
  const cookies = new Cookies()
  const navigate = useNavigate()
  const location = useLocation()
  const {sku} = useParams()
  const {state: {cart, userData, secret}, dispatch} = CartState()
  const token = cookies?.get("auth_token")
  const cart_id = window?.localStorage?.getItem("cart_id")
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(location?.state)
  const size_array = [8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 13]
  const colors = [1, 2, 3, 4, 5]
  const [count, setCount] = useState(1)
  const product_url = window.location.href
  const [showReviewInput, setShowReviewInput] = useState(false)
  const [summary, setSummary] = useState("")
  const [reviewContent, setReviewContent] = useState("")
  const [rating, setRating] = useState(0)
  const [reviewData, setReviewData] = useState([])
  const [reviewLoading, setReviewLoading] = useState(false)
  const [averageRating, setAverageRating] = useState(0)
  const [productAddedToCart, setProductAddedToCart] = useState(false)
  
  const oneWeekFromNow = new Date();
  oneWeekFromNow.setTime(oneWeekFromNow.getTime() + 604800 * 1000);
  const cookieOptions = {
    path: "/",
    expires: oneWeekFromNow,
    sameSite: "none",
    // secure: true,
  };

  const updateItemsInCart = (count) => {
      cookies.set('items_in_cart', count, cookieOptions);
  };

  // Function to get "items_in_cart" value from local storage
  const getItemsInCart = () => {
      return parseInt(cookies.get('items_in_cart')) || 0;
  }

   const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
  }
  

  const submit_review_url = `${process.env.REACT_APP_API_URL}/rest/V1/reviews`;
  const get_reviews_url = `${process.env.REACT_APP_API_URL}/rest/V1/products/${data?.sku}/reviews`;

  const getAvgRating = (reviewData) => {
    let totalRatingSum = 0;
    reviewData.forEach((review) => {
      const ratingSum = review.ratings.reduce(
        (acc, rating) => acc + rating.value,
        0
      );
      totalRatingSum += ratingSum;
    });

    // Calculate average rating
    const averageRating = totalRatingSum / reviewData.length;
    setAverageRating(averageRating);
  }

  const oauth = OAuth({
    consumer: {
      key: secret?.consumerKey,
      secret: secret?.consumerSecret,
    },
    signature_method: "HMAC-SHA256",
    hash_function(base_string, key) {
      return HmacSHA256(base_string, key).toString(enc.Base64); // Use HmacSHA256 from crypto-js
    },
  });

  const returnRequestData = (method, url) => {
    return {
      method: method,
      url: url
    }
  }

  // const requestData = {
  //   method: "POST",
  //   url: cancel_url,
  // };
  const submitReviewRequestHeaders = oauth.toHeader(
    oauth.authorize(returnRequestData("POST", submit_review_url), {
      key: secret?.accessToken,
      secret: secret?.accessTokenSecret,
    })
  );

  const getReviewsHeaders = oauth.toHeader(
    oauth.authorize(returnRequestData("GET", get_reviews_url), {
      key: secret?.accessToken,
      secret: secret?.accessTokenSecret,
    })
  );


  const list = [
    {
      topic: "Materials",
      info: "Hand-crafted from Italian cow leather. Ultrasuede interior. Comes with a detachable and adjustable shoulder strap."
    },
    {
      topic: "Dimensions",
      info: "39cm x 99cm"
    },
    {
      topic: "Care Instructions",
      info: "Wash it with warm water. Dry in the sun and use oil to condition the leather."
    }
  ]

  const images = [
    {
      url: `${process.env.REACT_APP_API_URL}/media/catalog/product` + data?.media_gallery_entries?.[0]?.file
    },
    {
      url: "https://images.unsplash.com/photo-1525547719571-a2d4ac8945e2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=764&q=80"
    },
    {
      url: "https://images.unsplash.com/photo-1549298916-b41d501d3772?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1112&q=80"
    },
    {
      url: "https://images.unsplash.com/photo-1524805444758-089113d48a6d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=688&q=80"
    }    
  ]

  const [mainImg, setMainImg] = useState(images?.[0]?.url)

   const handleAddtoCart = () => {
        if (token) {
            const payload = {
                "cartItem": {
                    "sku": data?.sku,
                    "qty": 1,
                    "quote_id": cart_id
                }
            }

            axios.post(`${process.env.REACT_APP_API_URL}/rest/V1/carts/mine/items`, payload, { headers: headers })
                .then((response) => {
                    console.log("response from add to cart: ", response?.data)
                  toast.dark(`✅ ${data?.name} is now added to your cart`, { closeOnClick: true })
                  setProductAddedToCart(true)
                    if (cart?.some((c) => c?.sku === data?.sku)) {
                        dispatch({
                            type: "INCREMENT",
                            payload: {
                                sku: data?.sku
                            }
                        })
                    } else {
                        dispatch({
                            type: "ADD_TO_CART",
                            payload: data
                        })
                        updateItemsInCart(getItemsInCart()+1)
                    }
                    console.log("Context: ", cart)
                })
                .catch((error) => {
                    console.log("error from add to cart: ", error)
                })
        } else {
            alert("You're not logged in. Redirecting you to the login page...")
            navigate("/login")
        }

  }
  
  const fetchProductInfo = () => {
    setLoading(true)
    axios.get(`${process.env.REACT_APP_API_URL}/rest/default/V1/products/${sku}`)
    .then((response) => {
        console.log('response from fetch img: ', response?.data)
      setData(response?.data)
      setMainImg(`${process.env.REACT_APP_API_URL}/media/catalog/product${response?.data?.media_gallery_entries?.[0]?.file}`)
      setLoading(false)
    })
    .catch((err) => {
      console.log("err from fetchImages: ", err);
      setLoading(false)
    });
  }

  const handleReviewSubmit = () => {
   
    console.log("Summary: ", summary)
    console.log("Review: ", reviewContent)
    console.log("secret from context: ", secret)
    if (rating !== 0) {
      const review_payload = {
        review: {
          title: summary,
          detail: reviewContent,
          nickname: userData?.firstname + " " + userData?.lastname,
          ratings: [
            {
              rating_name: "Rating",
              value: rating,
            },
            {
              rating_name: "Quality",
              value: rating,
            },
          ],
          review_entity: "product",
          review_status: 1,
          entity_pk_value: data?.id,
          customer_id: userData?.id,
        },
      };

      axios({
        method: "POST",
        url: submit_review_url,
        headers: submitReviewRequestHeaders,
        data: review_payload,
      })
        .then((response) => {
          toast.dark(`✅ Review submitted successfully`, {
            closeOnClick: true,
          });
          console.log("Response:", response?.data);
        })
        .catch((error) => {
          toast.dark(`❌ Error while submitting review. Please try again.`, {
            closeOnClick: true,
          });
          console.error("Error:", error);
        });
    } else {
     alert("Please select the rating before submitting the review.")
    }
    
  }

  const productShare = (text,title,url) => {
    if (navigator?.share) {
      navigator.share({
        text: text,
        title: title,
        url: url        
      });
    } else {
      navigator?.clipboard?.writeText(url);
    }
  };

  const getProductReviews = () => {
    setReviewLoading(true)
    axios({
      method: "GET",
      url: get_reviews_url,
      headers: getReviewsHeaders,
      
    })
    .then((response) => {
      setReviewData(response?.data?.reverse())
      console.log("review data received: ", response?.data)
      if(response?.data?.length > 0) getAvgRating(response?.data)
      setReviewLoading(false)
    })
    .catch((err) => {
      console.log("error while fetching prod reviews: ", err)  
      setReviewLoading(false)
    })

  }


  useEffect(() => {
    window.scrollTo(0, 0)
    // if (data?.name) {
    //   setMainImg(images?.[0]?.url)
    // } else {
    fetchProductInfo()
    getProductReviews()

    return () => {
      if (!productAddedToCart) {
        rudderAnalytics.track("product_bounce_rate", {
          ProductName: data?.name,
          sku: data?.sku,
        });
      }
    }

    // }
  }, [])

  usePageDurationTracker("Product Page");

  useEffect(() => {
    if (data?.name) {
      rudderAnalytics.track("Product Viewed", {
        ProductName: data?.name,
        sku: data?.sku,
      });
    }
  },[])
  
  return (
    <div className="flex flex-col font-outfit select-none items-center justify-start p-2">
      <div className="w-full p-2 md:px-4">
        <p
          className="hover:underline cursor-pointer md:px-4 rounded-md md:text-sm text-xs font-medium"
          onClick={() => navigate(-1)}
        >
          BACK
        </p>
      </div>
      <div className="w-[80%] flex flex-col md:flex-row justify-center items-start md:p-4 gap-2 m-0 ">
        <div className="md:w-1/2 w-full max-w-max p-2 flex flex-col gap-2">
          {loading ? (
            <div className="rounded-md bg-[#ECF2F4] w-[100px] h-[100px] loading-container p-4 flex justify-center items-center lg:h-[550px] lg:w-[400px]">
              <svg
                aria-hidden="true"
                className="w-5 h-5 mr-2 text-gray-200 animate-spin  fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          ) : (
            <img
              alt="ecommerce"
              className="block sm:rounded-xl border border-gray-300 lg:h-[550px] lg:w-[450px] object-fill lg:pointer-events-auto cursor-pointer"
              src={mainImg}
              width="100%"
              height="100%"
            />
          )}

          {/* <div className='flex justify-center items-center w-full p-4 gap-2'>
          {images?.map((image, index) => {
            return (
              <div className='cursor-pointer border border-gray-300 rounded-md flex justify-center items-center' onClick={()=>{setMainImg(images[index]?.url)}}>
                <img className='rounded-md hover:opacity-50 transition w-[70px] h-[70px] object-cover' src={image?.url} height="100%" alt='shoes' width="100%"/>
              </div>
            )
          })}
        </div> */}
          {/* <div className='hidden md:flex'>
          <h3 className='font-medium text-2xl'>About Product</h3>
          <p className='text-base font-normal'>Rooted in a dedication to innovation and helping people improve their game, Nike asserts that we're all athletes and strives to outfit each of us with the high-performance gear our workouts need. Founded in Oregon by a track athlete and his coach, this now globally renowned brand creates inventive shoes and apparel for athletes at all levels.</p>
          <img src="https://images.unsplash.com/photo-1525547719571-a2d4ac8945e2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=764&q=80"
           alt='product-logo' className='h-24 w-40 py-2' />
        </div> */}
        </div>

        <div className="md:w-1/2 w-full h-full flex flex-col items-start justify-start gap-3 p-1">
          {/* <p className="mb-4 cursor-pointer text-base md:text-lg font-semibold flex gap-3 items-center justify-center" onClick={() => navigate(-1)}><BsArrowLeft color='black' />Back</p> */}
          <p className="text-base text-gray-500">Vendor Name</p>
          <p className="md:text-3xl text-xl font-bold">{data?.name}</p>
          {/* ratings start */}
          {/* <div className='flex gap-2 items-center'>
            <p>4.3⭐</p>            
            <p className='text-[#888888] text-base'>(6 ratings)</p>
          </div> */}
          {/* ratings end */}
          <p className="font-semibold flex gap-4 items-center">
            <span className="md:text-2xl text-lg">${data?.price}.00</span>
            <span className="line-through font-normal md:text-2xl text-lg text-gray-400">
              $125.00
            </span>
          </p>
          <div className="w-full flex justify-between items-center">
            <p className="text-sm text-gray-400">Tax Included</p>
            <div className="flex">
              <button
                className="flex gap-1 hover:underline"
                onClick={() => {
                  productShare(
                    `Hey! Look I found this amazing product ${data?.name}`,
                    `Check out this amazing product shared by ${userData?.name}`,
                    product_url
                  );
                }}
              >
                <BiSolidShareAlt className="self-center" />
                Share
              </button>
              {/* <span className='flex gap-1'><ImTwitter className='self-center'/>Tweet</span>
            <span className='flex gap-1'><FaPinterestP className='self-center'/>Pin it</span> */}
            </div>
          </div>

          {/* <div className='lg:w-[60%] md:w-full sm:w-full'>
            <p className='w-full flex justify-between py-1 text-gray-500'>
              <span>Size</span><span className='hover:underline cursor-pointer'>Size Chart</span>
            </p>
            <div className='w-full flex flex-wrap items-center justify-start gap-3'>
              {size_array?.map((size, index) => {
              return <div className="flex hover:bg-black hover:text-white cursor-pointer  justify-center items-center w-11 h-11 border rounded-md border-gray-400" key={index}>{size}</div>
              })}
            </div>
          </div>
          
          <p className='text-gray-500'>Color: <span className='font-medium text-black'>Grey</span></p>
          <div className='flex flex-wrap items-center justify-start gap-3'>
            {colors?.map((color, index) => {
              return<div key={index} className='p-1 border border-[#000000] md:w-10 rounded-md md:h-10 w-6 h-6 cursor-pointer'> <div className='w-full h-full rounded-md cursor-pointer bg-gradient-to-r from-purple-600 to-blue-600' ></div></div>
            })}
          </div> */}
          {/* <p className='text-base font-medium'>Last 1 item left :<span className='text-base font-normal'>{" "}hurry up!</span></p> */}
          <div className="flex flex-col w-full lg:w-[60%] md:w-full sm:w-full gap-3">
            {/*<p className='text-gray-500'>Quantity</p>
             <div className='px-4 py-3 border rounded-md w-full bg-white text-black text-sm font-semibold flex justify-between border-gray-400'>
              <p className='font-semibold text-xl cursor-pointer' onClick={()=>setCount(count > 1 ? count - 1 : 1)}>-</p>
              <p className='font-semibold text-base'>{count}</p>
              <p className='font-semibold text-xl cursor-pointer' onClick={()=>setCount(count + 1)}>+</p>
            </div> */}
            {/* <button className='bg-black px-4 py-3 rounded-md text-white w-full  font-semibold text-sm md:font-xl'>BUY NOW</button> */}
            <button
              className="px-4 py-3 border w-[180px] rounded-md hover:bg-slate-800 text-sm font-semibold  text-white bg-black"
              onClick={() => {
                handleAddtoCart();
              }}
            >
              ADD TO CART
            </button>
          </div>

          {/* <p className='flex gap-3 items-center text-gray-600 cursor-pointer mt-2'><IoShareSocial /> Share</p>*/}
          <div className="w-full flex flex-col items-start justify-center gap-3">
            <p className="text-lg font-semibold">Product Details</p>
            <p className="text-gray-500 font-medium">
              {data?.name} from Nike's largest Air Max unit puts a distinctive
              footprint under the heel of a cloud-comfort sneaker sporting a
              sock-knit upper branded with welded Swooshes.
            </p>
          </div>

          <div className="w-full">
            {list?.map((item) => {
              return <Accordion data={item} />;
            })}
          </div>
        </div>
      </div>
      {/*Product Review Section*/}
      <div className="w-[80%] rounded-md p-2 flex flex-col justify-start items-center gap-2">
        <div className="w-full p-2 flex justify-between items-center">
          <p className="font-bold text-2xl">Reviews</p>
          <p className="font-bold text-2xl flex items-center gap-1">
            <span>⭐</span>
            <span>{averageRating.toFixed(2)}</span>
          </p>
        </div>
        <hr className="w-full p-2" />
        <div className="w-full p-2 flex justify-between items-center py-4">
          <div>
            <button
              className="p-2 rounded-md w-[150px] h-[40px] text-white bg-[#EA8B24] flex items-center justify-center hover:bg-[#D77716]"
              onClick={() => setShowReviewInput(!showReviewInput)}
            >
              <p>Add a Review</p>
            </button>
          </div>
          <div>
            <select
              name="review_sort"
              id="review_sort"
              className="border border-gray-200 p-2 rounded-sm"
            >
              <option value="" defaultValue className="p-1">
                Sort By
              </option>
              <option value={1} className="p-1">
                Top Rated Reviews
              </option>
              <option value={2} className="p-1">
                Recent Reviews
              </option>
            </select>
          </div>
        </div>
        {showReviewInput && (
          <form
            className={`flex w-full justify-between items-start flex-col gap-2 ${
              showReviewInput ? "opacity-100 h-auto py-1" : "opacity-0 h-0"
            } overflow-hidden transition-all duration-500 ease-in-out`}
            onSubmit={handleReviewSubmit}
          >
            <div
              className={`w-full p-2 flex flex-col gap-1 ${
                showReviewInput ? "opacity-100 h-auto py-1" : "opacity-0 h-0"
              } overflow-hidden transition-all duration-500 ease-in-out`}
            >
              <p>Summary</p>
              <input
                type="text"
                name="review-summary"
                value={summary}
                onChange={(e) => setSummary(e?.target?.value)}
                id=""
                className="w-full border border-gray-300 p-2 outline-none rounded-md"
                placeholder="Title/Summary of the review"
                required
              />
            </div>
            <div
              className={`w-full p-2 flex flex-col gap-1 ${
                showReviewInput ? "opacity-100 h-auto py-1" : "opacity-0 h-0"
              } overflow-hidden transition-all duration-500 ease-in-out`}
            >
              <p>Rating</p>
              <StarRating rating={rating} setRating={setRating} />
            </div>
            <div
              className={`w-full p-2 flex flex-col gap-1 ${
                showReviewInput ? "opacity-100 h-auto py-1" : "opacity-0 h-0"
              } overflow-hidden transition-all duration-500 ease-in-out`}
            >
              <p>Review</p>
              <textarea
                name="review-content"
                id=""
                value={reviewContent}
                className="w-full border border-gray-300 p-2 outline-none rounded-md"
                rows={3}
                placeholder="Write your review in detail here"
                onChange={(e) => setReviewContent(e?.target?.value)}
                required
              />
            </div>
            <div
              className={`w-full p-2 flex justify-between items-center gap-1 ${
                showReviewInput ? "opacity-100 h-auto py-1" : "opacity-0 h-0"
              } overflow-hidden transition-all duration-500 ease-in-out`}
            >
              <button className="p-2 rounded-md w-[150px] h-[40px] text-white bg-[#EA8B24] flex items-center justify-center hover:bg-[#D77716]">
                Submit Review
              </button>
              <button
                className="p-2 rounded-md w-[150px] h-[40px] text-white bg-black flex items-center justify-center hover:bg-slate-900"
                onClick={() => setShowReviewInput(false)}
              >
                Cancel
              </button>
            </div>
          </form>
        )}
        <div className="min-h-[100px] w-full p-2">
          {loading ? (
            <Loader />
          ) : reviewData?.length > 0 ? (
            <div className="w-full flex flex-col divide-y-2 divide-gray-300 justify-start items-center">
              {reviewData?.map((review, index) => {
                return (
                  <ReviewCard
                    reviewData={review}
                    key={review?.id + index + review?.title}
                  />
                );
              })}
            </div>
          ) : (
            <div>No reviews found</div>
          )}
        </div>
      </div>

      {/* <div className='lg:mx-32 md:mx-24 sm:mx-12 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid gap-3 items-center'>
            <div className='bg-[#F0F0F0] p-4'>
              <div>
                <h4 className='font-medium text-2xl py-2'>Gift options</h4>
                <p className='text-base font-normal'>Choose your gift options at Checkout. Some items may not be eligible for all gift options</p>
              </div>
              <div className='flex gap-3'>
                <div>
                  <h4 className='text-2xl font-medium py-2'>Free Pick up</h4>
                  <ul className='font-normal text-base'>
                    <li className='py-1 flex gap-2'><FaPinterestP className='self-center'/>Printed Gift Message (free)</li>
                    <li className='py-1 flex gap-2'> <FaPinterestP className='self-center'/>Fabric Gift bag ($5)</li>
                    <li className='py-1 flex gap-2'> <FaPinterestP className='self-center'/>DIY Nordstrom gift box ($5)</li>
                    <li className='py-1 flex gap-2'> <FaPinterestP className='self-center'/>Signature Wrap (free)</li>
                  </ul>
                </div>
                <div>
                  <h4 className='text-2xl font-medium py-2'>Delivery</h4>
                  <ul className='font-normal text-base'>
                    <li className='py-1 flex gap-2'><FaPinterestP className='self-center'/>Email gift message (free)</li>
                    <li className='py-1 flex gap-2'> <FaPinterestP className='self-center'/>Printed Gift Message (free)</li>
                    <li className='py-1 flex gap-2'> <FaPinterestP className='self-center'/>Fabric Gift bag ($5)</li>
                    <li className='py-1 flex gap-2'> <FaPinterestP className='self-center'/>DIY Nordstrom gift box ($5)</li>
                  </ul>
                </div>
              </div>
              <div>
                <p className='text-base font-medium py-3'>Need help finding the perfect gift? We've got you covered.</p>
                <button className='uppercase font-normal text-sm bg-white px-5 py-2 border-[1px] border-black'>Shop a Gift</button>
              </div>
            </div>
            <div className='bg-[#F0F0F0] p-4'>
              <div>
                <h4 className='text-2xl font-medium py-2'>Shipping and return policies</h4>
                <p className='text-base font-normal pt-3'>Estimated arrival (if ordered today)</p>
                <h4 className='text-[34px] font-semibold'>June 31, 2023</h4>
              </div>
              
                <div className="flex items-center justify-between py-3">
                  <span className="rounded-full p-2 bg-white text-gray-500"><FaPinterestP/></span>
                <hr className="w-full border-t-2 border-gray-300"/>
                  <span className="rounded-full p-2 bg-white text-gray-500"><FaPinterestP/></span>
                <hr className="w-full border-t-2 border-gray-300"/>
                  <span className="rounded-full p-2 bg-white text-gray-500"><FaPinterestP/></span>
              </div>

              <div className="flex items-center justify-between">
                <div className='flex flex-col items-start'>
                  <span className='text-base font-medium'>June 24 </span>
                  <span className='text-base font-normal'>Order placed</span>
                </div>
                <div className='flex flex-col items-center'>
                  <span className='text-base font-medium'>June 24 </span>
                  <span className='text-base font-normal'>Order placed</span>
                </div>
                
                <div className='flex flex-col items-end'>
                  <span className='text-base font-medium'>June 24 </span>
                  <span className='text-base font-normal'>Order placed</span>
                </div>
              </div>

              <div className='flex gap-12 py-3'>
                <div className='flex flex-col'>
                  <p className='text-base font-medium py-1'>cost to ship</p>
                  <h4 className='font-semibold text-2xl'>Free</h4>
                </div>
                <div>
                <p className='text-base font-medium py-1'>Returns</p>
                  <h4 className='font-semibold text-2xl'>Accepted</h4>
                  <p className='text-base font-medium py-1'>Exceptions may apply</p>
                </div>
              </div>

              <div>
                <p className='text-base font-medium py-1'>Delivers to [Name of the area where the product can be shipped]</p>
                <p className='text-base font-medium py-1'>Ships form [name of place where the product ships from]</p>
              </div>
              </div>
      </div> */}

      {/* <div className='flex gap-3 lg:mx-32 md:mx-24 sm:mx-12 py-10 border-b-[1px] border-[#D9D9D9] w-full'>
        <div className='lg:col-span-1 md:col-span-full sm:col-span-full flex flex-col'>
          <div className='ratings flex flex-col gap-3 p-2'>
              <p className='md:text-lg font-semibold'>Ratings</p>
              <p className='md:text-base font-medium flex gap-2'><span>⭐⭐⭐⭐⭐</span><span>(6)</span></p>
              <p className='md:text-base'>4.3 out of 5</p>
          </div>
        </div>
        <div className='w-1/3 p-2 flex-col'>
          <p className='md:text-lg font-semibold'>Filter reviews by</p>
          <div className='flex flex-col items-start p-2'> 
              <div className='flex gap-3 items-center md:justify-center justify-start md:p-3 p-2'>
                <input type="checkbox" className="w-4 h-4 text-blue-600 cursor-pointer bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"name="" id="" />
                <p className='md:text-base text-sm font-medium'>5 Stars</p>
                <div className="bg-gray-300 h-3 md:w-[200px] w-[150px]">
                  <div className="bg-gray-700 p-0.5 text-center text-xs font-medium leading-none text-primary-100 w-[75%] h-full" />
                </div>
              </div>

              <div className='flex gap-3 items-center justify-center p-3'>
              <input type="checkbox" name="" id="" className='w-4 h-4 text-blue-600 cursor-pointer bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'/>
              <p className='md:text-base font-medium'>4 Stars</p>
              <div className="bg-gray-300 h-3 w-[200px]">
                <div className="bg-gray-700 p-0.5 text-center text-xs font-medium leading-none text-primary-100 w-[55%] h-full" />
              </div>
            </div>

            <div className='flex gap-3 items-center justify-center p-3'>
              <input type="checkbox" name="" id="" className='w-4 h-4 text-blue-600 cursor-pointer bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'/>
              <p className='md:text-base font-medium'>3 Stars</p>
              <div className="bg-gray-300 h-3 w-[200px]">
                <div className="bg-gray-700 p-0.5 text-center text-xs font-medium leading-none text-primary-100 w-[20%] h-full" />
              </div>
            </div>

            <div className='flex gap-3 items-center justify-center p-3'>
              <input type="checkbox" name="" id="" className='w-4 h-4 text-blue-600 cursor-pointer bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'/>
              <p className='md:text-base font-medium'>2 Stars</p>
              <div className="bg-gray-300 h-3 w-[200px]" />
                
            </div>

            <div className='flex gap-3 items-center justify-center p-3'>
              <input type="checkbox" name="" id="" className='w-4 h-4 text-blue-600 cursor-pointer bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'/>
              <p className='md:text-base font-medium'>1 Stars</p>
              <div className="bg-gray-300 h-3 w-[200px]" />
                
            </div>
            
          </div>
        </div>
        <div className='col-span-1 flex items-end justify-center mr-2'>
          <button className=' self-endtext-center px-8 py-2 text-sm font-medium cursor-pointer border border-gray-400 rounded-sm'>WRITE A REVIEW</button>
        </div>
      </div>

      <div className="w-full py-3 px-6 flex flex-col items-center mt-10 font-outfit">
        <div className='w-[85%] flex flex-col gap-5'>
          <div className='flex justify-between'>
          <p className='md:text-xl'>Customer Reviews</p>
          <select name="Sort" id="Sort" className='border-[1px] border-black p-3 text-base'> 
            <option value="Price">Sort By</option> 
            <option value="option2">option2</option> 
          </select>
        </div>

        <div className='single-review w-full flex md:flex-row flex-col gap-2'>
            <div className='flex flex-col gap-3 md:w-1/4 w-full'>
              <div className='flex gap-3 items-center'>
                <div className="w-[40px] h-[40px] rounded-full bg-gray-400" />
                <p className='text-sm'>Username</p>
              </div>
              <p className='md:text-sm font-medium flex gap-2'><span>⭐⭐⭐⭐⭐</span><span className='text-gray-500'>13/12/1199</span></p>
              <p className='text-gray-500 text-sm'>Size purchased: <span className='text-black font-medium'>6 UK</span></p>
              <p className='text-gray-500 text-sm'>Color: <span className='text-black font-medium'>Black</span></p>
              <p className='text-gray-500 text-sm'>Fit: <span className='text-black font-medium'>Perfect Fit</span></p>
            </div>
            <div className='user-actual-review md:w-3/4'>
              Perfect neutral shoe (tan) I have been looking for a neutral block heel sandal for months. I have ordered and sent back numerous pairs due to heel height, sizing issues, discomfort, etc. This heel height is perfect for everyday, great color for summer, and I love that the shoe is not suede or a leather covered heal. This is a practical style for any spring or summer weather and is comfortable. The price on this shoe is great. I have paid double this amount for a shoe that is uncomfortable or material that is too fussy.

            </div>
            <div className='flex gap-3 md:w-1/3 w-full'>
              <div className='w-[150px] h-[150px] bg-gray-400' />
              <div className='w-[150px] h-[150px] bg-gray-400'/>
            </div>
          </div>
          <div className='single-review w-full flex md:flex-row flex-col gap-2'>
            <div className='flex flex-col gap-3 md:w-1/4'>
              <div className='flex gap-3 items-center'>
                <div className="w-[40px] h-[40px] rounded-full bg-gray-400" />
                <p className='text-sm'>Username</p>
              </div>
              <p className='md:text-sm font-medium flex gap-2'><span>⭐⭐⭐⭐⭐</span><span className='text-gray-500'>13/12/1199</span></p>
              <p className='text-gray-500 text-sm'>Size purchased: <span className='text-black font-medium'>6 UK</span></p>
              <p className='text-gray-500 text-sm'>Color: <span className='text-black font-medium'>Black</span></p>
            </div>
            <div className='user-actual-review md:w-3/4 w-full'>
              Perfect neutral shoe (tan) I have been looking for a neutral block heel sandal for months. I have ordered and sent back numerous pairs due to heel height, sizing issues, discomfort, etc. This heel height is perfect for everyday, great color for summer, and I love that the shoe is not suede or a leather covered heal. This is a practical style for any spring or summer weather and is comfortable. The price on this shoe is great. I have paid double this amount for a shoe that is uncomfortable or material that is too fussy.

            </div>
            <div className='flex gap-3 md:w-1/3'>
              <div className='w-[150px] h-[150px] bg-gray-400' />
              <div className='w-[150px] h-[150px] bg-gray-400'/>
            </div>
          </div>
          <div className="more w-full flex justify-center items-center p-2 mt-5">
            <p className='cursor-pointer hover:underline'>Load more reviews</p>
          </div>
      </div>
      </div>

      <div className="w-full py-3 px-6 flex flex-col items-center font-outfit">
        <div className='w-[85%] flex flex-col gap-5'>
        <p className='md:text-xl'>Customer photos and videos</p>
        <div className='w-full flex gap-4 flex-wrap'>
            {colors?.map((color) => {
              return <div className='w-[200px] h-[240px] bg-gray-400'/>
            })}
        </div>
      </div>
      </div> */}

      {/* <div className="w-full py-3 md:px-6 p-3 flex flex-col md:items-center items-start md:mt-10 font-outfit">
        <div className='md:w-[85%] w-full flex flex-col gap-5'>
          <p className='md:text-xl text-base'>Recommended for you</p>
          <div className='w-full flex md:gap-4 gap-y-4 gap-x-2 flex-wrap'>
            {colors?.map((color, index) => {
              return <div key={index} className='md:w-[200px] md:h-[240px] w-[140px] h-[160px] rounded-md bg-gray-400'/>
            })}
        </div>
        </div>
      </div>

      <div className="w-full py-3 md:px-6 p-3 flex flex-col md:items-center items-start md:mt-10 font-outfit">
        <div className='md:w-[85%] w-full flex flex-col gap-5'>
          <p className='md:text-xl text-base'>You may also like</p>
          <div className='w-full flex md:gap-4 gap-y-4 gap-x-2 flex-wrap'>
            {colors?.map((color, index) => {
              return <div key={index} className='md:w-[200px] md:h-[240px] w-[140px] h-[160px] rounded-md bg-gray-400'/>
            })}
        </div>
        </div>
      </div> */}
      <div className="font-outfit text-sm ">
        <ToastContainer position="bottom-left" autoClose={4000} type="dark" />
      </div>
    </div>
  );
}

export default ProductPage