import axios from "axios";
import AWS from "aws-sdk";
import Cookies from "universal-cookie";
import { useEffect } from "react";
import rudderAnalytics from "./rudderstack";

export const getCart = (cartHeaders, updateCart, loader) => {
  console.log("Loader function:", loader);
  const cookie = new Cookies();
  axios
    .get(`${process.env.REACT_APP_API_URL}/rest/default/V1/carts/mine`, {
      headers: cartHeaders,
    })
    .then((response) => {
      console.log("cartData: ", response?.data?.items);
      updateCart(response?.data?.items);
      const twoHoursFromNow = new Date(Date.now() + 2 * 60 * 60 * 1000);
      const cookieOptions = {
        path: "/",
        expires: twoHoursFromNow,
        sameSite: "lax", // You can adjust this based on your needs
        // secure: true,    // Set to 'true' if using HTTPS
      };
      const count = response?.data?.items?.length;
      console.log("cookie options: ", cookieOptions);
      console.log("items_in_cart: ", response?.data?.items?.length);

      cookie.set("items_in_cart", count, cookieOptions);
      if (loader) loader(false);

      // localStorage?.setItem("items_in_cart", response?.data?.items?.length);
    })
    .catch((error) => {
      console.log("Error while fetching active cart", error);
      if (loader) loader(false);
    });
};

export const fetchSecret = (secretValue) => {
  const secretName = "Magento_Admin_Oauth";
  const awsConfig = {
    region: "ap-south-1", // Replace with your AWS region (e.g., 'us-east-1')
    accessKeyId: "AKIAU7OHNFO5PTHB47OD", // Replace with your access key ID
    secretAccessKey: "VTkSVgUDHYi2/vvZKPnB/DS0Bl0m2QrTb+0Vu7", // Replace with your secret access key
    // region: "ap-south-1", // Replace with your AWS region (e.g., 'us-east-1')
    // accessKeyId: "AKIAU7OHNFO5ETHB47OD", // Replace with your access key ID
    // secretAccessKey: "VTkSVgUDHYi2/vvZKPnB/DS0Bl0m2QrTb+0Vu7Tj", // Replace with your secret access key
  };
  AWS.config.update(awsConfig);
  const secretsManager = new AWS.SecretsManager();
  secretsManager.getSecretValue({ SecretId: secretName }, (err, data) => {
    console.log("Service is running");
    if (err) {
      console.error("Error retrieving the secret:", err);
    } else {
      const secret = JSON.parse(data?.SecretString);
      console.log("secret key: ", secret);
      secretValue(secret);
    }
  });
};

export const fetchProducts = (updateProducts) => {
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/rest/V1/products?searchCriteria[pageSize]=10`
    )
    .then((res) => {
      updateProducts(res?.data?.items);
      if (!localStorage?.getItem("products")) {
        localStorage?.setItem("products", JSON.stringify(res?.data?.items));
      }
    })
    .catch((err) => {
      console.log("Error while fetching products: ", err);
    });
};



export const usePageTracking = () => {
  useEffect(() => {
    const entryTime = Date.now();
    const sessionId =
      sessionStorage.getItem("sessionId") ||
      Math.random().toString(36).substring(2);
    sessionStorage.setItem("sessionId", sessionId);

    const pagesViewed = JSON.parse(sessionStorage.getItem("pagesViewed")) || [];
    pagesViewed.push(window.location.pathname);
    sessionStorage.setItem("pagesViewed", JSON.stringify(pagesViewed));

    rudderAnalytics.page();
    rudderAnalytics.track("Page Viewed", { path: window.location.pathname });

    const handleBeforeUnload = () => {
      const exitTime = Date.now();
      const timeSpent = (exitTime - entryTime) / 1000;

      const totalTimeSpent =
        parseFloat(sessionStorage.getItem("totalTimeSpent")) || 0;
      sessionStorage.setItem("totalTimeSpent", totalTimeSpent + timeSpent);

      if (timeSpent < 30) {
        rudderAnalytics.track("Bounce", {
          path: window.location.pathname,
          timeSpent,
        });
      } else {
        rudderAnalytics.track("Page Exited", {
          path: window.location.pathname,
          timeSpent,
        });
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [window.location.pathname]);

  // useEffect(() => {
  //   const handlePageHide = () => {
  //     const totalTimeSpent =
  //       parseFloat(sessionStorage.getItem("totalTimeSpent")) || 0;
  //     const pagesViewed =
  //       JSON.parse(sessionStorage.getItem("pagesViewed")) || [];

  //     rudderAnalytics.track("Session End", {
  //       totalTimeSpent,
  //       pagesViewed,
  //     });

  //     sessionStorage.removeItem("totalTimeSpent");
  //     sessionStorage.removeItem("pagesViewed");
  //     sessionStorage.removeItem("sessionId");
  //   };

  //   window.addEventListener("pagehide", handlePageHide);

  //   return () => {
  //     window.removeEventListener("pagehide", handlePageHide);
  //   };
  // }, []);
};





// import axios from 'axios'
// import AWS from "aws-sdk";
// import Cookies from 'universal-cookie'

// export const getCart = (cartHeaders, updateCart) => {
//   const cookie = new Cookies();
//   axios.get(`${process.env.REACT_APP_API_URL}/rest/default/V1/carts/mine`, {headers: cartHeaders})
//       .then((response) => {
//         console.log("cartData: ", response?.data?.items);
//         updateCart(response?.data?.items);
//         const oneWeekFromNow = new Date();
//         oneWeekFromNow.setTime(oneWeekFromNow.getTime() + 604800 * 1000);
//         const cookieOptions = {
//           path: "/",
//           expires: oneWeekFromNow,
//           sameSite: "lax", // You can adjust this based on your needs
//           // secure: true,    // Set to 'true' if using HTTPS
//         };
//         const count = response?.data?.items?.length;
//         console.log("cookie options: ", cookieOptions)
//         console.log("items_in_cart: ", response?.data?.items?.length);
//         cookie.set("items_in_cart", count, cookieOptions);
//         // localStorage?.setItem("items_in_cart", response?.data?.items?.length);
//     })
//     .catch((error) => {
//       console.log("Error while fetching active cart", error);
//     });
// };

// export const fetchSecret = (secretValue) => {
//   const secretName = "Magento_Admin_Oauth";
//   const awsConfig = {
//     region: "ap-south-1", // Replace with your AWS region (e.g., 'us-east-1')
//     accessKeyId: "AKIAU7OHNFO5PTHB47OD", // Replace with your access key ID
//     secretAccessKey: "VTkSVgUDHYi2/vvZKPnB/DS0Bl0m2QrTb+0Vu7", // Replace with your secret access key
//     // region: "ap-south-1", // Replace with your AWS region (e.g., 'us-east-1')
//     // accessKeyId: "AKIAU7OHNFO5ETHB47OD", // Replace with your access key ID
//     // secretAccessKey: "VTkSVgUDHYi2/vvZKPnB/DS0Bl0m2QrTb+0Vu7Tj", // Replace with your secret access key
//   };
//   AWS.config.update(awsConfig);
//   const secretsManager = new AWS.SecretsManager();
//   secretsManager.getSecretValue({ SecretId: secretName }, (err, data) => {
//     console.log("Service is running");
//     if (err) {
//       console.error("Error retrieving the secret:", err);
//     } else {
//       const secret = JSON.parse(data?.SecretString);
//       console.log("secret key: ", secret);
//       secretValue(secret)
//     }
//   });
// };

// export const fetchProducts = (updateProducts) => {
//   axios.get(`${process.env.REACT_APP_API_URL}/rest/V1/products?searchCriteria[pageSize]=10`)
//   .then((res) => {
//     updateProducts(res?.data?.items);
//     if (!localStorage?.getItem("products")) {
//       localStorage?.setItem("products", JSON.stringify(res?.data?.items));
//     }
//   })
//   .catch((err) => {
//     console.log("Error while fetching products: ", err);
//   });
// };

//old
// import axios from 'axios'
// import AWS from "aws-sdk";
// import Cookies from 'universal-cookie'

// export const getCart = (cartHeaders, updateCart) => {
//   const cookie = new Cookies();
//   axios.get(`${process.env.REACT_APP_API_URL}/rest/default/V1/carts/mine`, {headers: cartHeaders})
//       .then((response) => {
//         console.log("cartData: ", response?.data?.items);
//         updateCart(response?.data?.items);
//         const oneWeekFromNow = new Date();
//         oneWeekFromNow.setTime(oneWeekFromNow.getTime() + 604800 * 1000);
//         const cookieOptions = {
//           path: "/",
//           expires: oneWeekFromNow,
//           sameSite: "lax", // You can adjust this based on your needs
//           // secure: true,    // Set to 'true' if using HTTPS
//         };
//         const count = response?.data?.items?.length;
//         console.log("cookie options: ", cookieOptions)
//         console.log("items_in_cart: ", response?.data?.items?.length);
//         cookie.set("items_in_cart", count, cookieOptions);
//         // localStorage?.setItem("items_in_cart", response?.data?.items?.length);
//     })
//     .catch((error) => {
//       console.log("Error while fetching active cart", error);
//     });
// };

// export const fetchSecret = (secretValue) => {
//   const secretName = "Magento_Admin_Oauth";
//   const awsConfig = {
//     region: "ap-south-1", // Replace with your AWS region (e.g., 'us-east-1')
//     accessKeyId: "AKIAU7OHNFO5ETHB47OD", // Replace with your access key ID
//     secretAccessKey: "VTkSVgUDHYi2/vvZKPnB/DS0Bl0m2QrTb+0Vu7Tj", // Replace with your secret access key
//   };
//   AWS.config.update(awsConfig);
//   const secretsManager = new AWS.SecretsManager();
//   secretsManager.getSecretValue({ SecretId: secretName }, (err, data) => {
//     console.log("Service is running");
//     if (err) {
//       console.error("Error retrieving the secret:", err);
//     } else {
//       const secret = JSON.parse(data?.SecretString);
//       console.log("secret key: ", secret);
//       secretValue(secret)
//     }
//   });
// };

// export const fetchProducts = (updateProducts) => {
//   axios.get("http://65.0.150.104/rest/V1/products?searchCriteria[pageSize]=10")
//   .then((res) => {
//     updateProducts(res?.data?.items);
//     if (!localStorage?.getItem("products")) {
//       localStorage?.setItem("products", JSON.stringify(res?.data?.items));
//     }
//   })
//   .catch((err) => {
//     console.log("Error while fetching products: ", err);
//   });
// };
