import React, {useEffect} from 'react'
import { FaCheck } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import axios from 'axios'

const OrderReview = () => {
    const navigate = useNavigate();
    const auth_token = window?.localStorage?.getItem("auth_token")

    // const order_headers = {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${auth_token}`
    // }
    
    // const placeOrder = () => {
    //     const req_url = "http://65.0.150.104/rest/V1/carts/mine/order"

    //     axios.put(req_url, { headers: order_headers })
    //     .then((response) => {
    //         console.log("order placed: ", response?.data)
    //         toast.dark(`✅ Order placed successfully`, {closeOnClick: true})
    //     })
    //     .catch((err) => {
    //         console.log("Error while placing the order: ", err?.response?.data)
    //         toast.dark(`❗Error while placing order`, {closeOnClick: true})
    //     })

    // }
    useEffect(() => {
        // placeOrder();
        window.scrollTo(0, 0) 
    },[])
  return (
    <div className='w-full font-outfit flex flex-col items-start justify-start gap-4'>
        <div className='w-full hidden lg:flex gap-3 items-center justify-center font-medium '>
              <div className='flex gap-3 items-center'>
                  <p className='rounded-full bg-green-500 text-white p-2 w-6 h-6 flex items-center justify-center text-sm'><FaCheck /></p>
                  <p>Information</p>
              </div>
              <hr className='h-0.5 w-[60px] border-t-0 bg-black opacity-100 dark:opacity-50'/>
              <div className='flex gap-3 items-center'>
                  <p className='rounded-full bg-green-500 text-white p-2 w-6 h-6 flex items-center justify-center  text-sm'><FaCheck /></p>
                  <p>Payment</p>
              </div>
              <hr className='h-0.5 w-[60px] border-t-0 bg-black opacity-100 dark:opacity-50'/>
              <div className='flex gap-3 items-center'>
                  <p className='rounded-full bg-green-500 text-white p-2 w-6 h-6 flex items-center justify-center  text-sm'><FaCheck /></p>
                  <p>Order Placed</p>
              </div>
            </div> 
            <div className="border-0 border-[rgb(96,96,96)] p-3 mt-5  flex justify-between bg-[#F0F2F6]">
                {/* <img src={debitcard} alt="logo" className="px-1 h-auto max-w-full" /> */}
                <div className="px-2">
                    <h4 className="text-xl font-bold font-sans flex " style={{ alignItems: 'center' }}> <BsFillCheckCircleFill size={35} className="text-green-500 mr-4" />Thank you for placing your order!</h4>
                    <p className="text-base text-[#606060] font-sans font-normal">
                        Your order has been
                        placed and is being processed. When the items are
                        shipped you will receive an email
                        and SMS notifications on the contact information provided.</p>
                </div>
            </div>
                        <div className="flex w-full justify-between mt-3 border-b-2 border-[#000000] p-3 py-4 h-auto bg-[#F0F2F6] items-center">
                            <p className="font-bold text-xl	" style={{ alignItems: 'center' }}>Order id : 123456</p>
                            <button type="submit" className='px-4 py-3 border rounded-md bg-white text-black  text-sm font-semibold border-gray-400'>
                                DONWLOAD INVOICE
                            </button>
          </div>
          <div className="w-full flex justify-between px-3"> 
              <p className="ml-3 mt-2 flex text-lg">Order Date : 19/09/1909 </p>
              <p className="ml-3 mt-2 flex text-lg">Estimated Delivery Date: 19/09/1909 </p>
          </div>
                        
            <div className="border-0 w-full border-[#303030] border-y-2  p-3 mt-5   bg-[#F0F2F6]">
                <p className="font-bold text-lg">Payment and Shipping details</p>
                <p className="py-3">Payment Method : Debit Card</p>
                <p className="py-3">Deliver To : Adrianna Johnson</p>
                <p className="py-3">Delivery Address: 60 Sunrise Dr, Lebanon, KY, 40033 67 Glenwood Blvd, Mansfield, OH, 44906</p>
                <p className="py-3">Delivery Method: FedEx Delivery</p>
            </div>
          <div className='p-3 w-full flex justify-between items-center'>
                <button className='font-sm text-black border-black border rounded-md cursor-pointer text-sm px-4 py-2 flex justify-center items-center' onClick={() => {
                  navigate('/cart')
                }}>TRACK MY ORDER</button>
                <button className='font-sm text-white rounded-md bg-black cursor-pointer text-sm px-4 py-2 flex justify-center items-center' onClick={() => {
                }}>CONTINUE TO SHOPPING</button>
          </div>
          
      </div>
      
  )
}

export default OrderReview