import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { CartState } from "../../context/Context.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineHeart } from 'react-icons/ai'
import { BiBarChart } from 'react-icons/bi'
import { BsFillCartPlusFill } from 'react-icons/bs'
import Cookies from 'universal-cookie'

const ProductCard = ({ data }) => {
    const cookies = new Cookies()
    const { state: { cart, userData, compare }, dispatch } = CartState();
    const [imageUrl, setImageUrl] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const items_in_cart =  Number(cookies.get('items_in_cart'))
    // const header_token = { "username": "admin", "password": "Admin@12345" }
    const token = cookies?.get("auth_token")
    const cart_id = window?.localStorage?.getItem("cart_id")
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }

    const oneWeekFromNow = new Date();
    oneWeekFromNow.setTime(oneWeekFromNow.getTime() + 604800 * 1000);
    const cookieOptions = {
        path: "/",
        expires: oneWeekFromNow,
        sameSite: "none",
    //   secure: true,
    };

    const updateItemsInCart = (count) => {
        console.log("count from update Itemsfromcart")
        cookies.set('items_in_cart', count, cookieOptions);
    };

    // Function to get "items_in_cart" value from local storage
    const getItemsInCart = () => {
        return parseInt(cookies.get('items_in_cart')) || 0;
    }

    const handleAddtoCart = () => {
        if (token) {
            const payload = {
                "cartItem": {
                    "sku": data?.sku,
                    "qty": 1,
                    "quote_id": cart_id
                }
            }

            axios.post(`${process.env.REACT_APP_API_URL}/rest/V1/carts/mine/items`, payload, { headers: headers })
                .then((response) => {
                    console.log("response from add to cart: ", response?.data)
                    toast.dark(`✅ ${data?.name} is now added to your cart`, { closeOnClick: true })
                    if (cart?.some((c) => c?.sku === data?.sku)) {
                        dispatch({
                            type: "INCREMENT",
                            payload: {
                                sku: data?.sku
                            }
                        })
                    } else {
                        dispatch({
                            type: "ADD_TO_CART",
                            payload: data
                        })
                        if (items_in_cart !== undefined) {
                            const count = Number(items_in_cart + 1)
                            cookies?.set("items_in_cart", count)
                            console.log(`The value of items_in_cart: ${cookies?.get("items_in_cart")} and it's type is ${typeof(items_in_cart)}`)
                        } else {
                            cookies?.set("items_in_cart", 1, cookieOptions)
                            console.log("Cookies not present: ")
                        }
                    }
                    console.log("Context: ", cart)

                })
                .catch((error) => {
                    console.log("error from add to cart: ", error)
                })
        } else {
            alert("You're not logged in. Redirecting you to the login page...")
            navigate("/login")
        }

    }

    const addToCompare = () => {
        if (!compare?.some((product) => product?.id === data?.id) && compare?.length < 4) {
            dispatch({
                type: "ADD_TO_COMPARE",
                payload: data
            })
            toast.dark(`✅ ${data?.name} is added to Comparison List`, { closeOnClick: true })
        } else if (compare?.length === 0) {
            dispatch({
                type: "ADD_TO_COMPARE",
                payload: data
            })
            toast.dark(`✅ ${data?.name} is added to Comparison List`, { closeOnClick: true })
        }
        console.log("compare array: ", compare)
    }

    const addToWishlist = () => {
        if (userData?.id) {
            axios.post(`${process.env.REACT_APP_API_URL}/rest/V1/wishlist/customer/product/${userData?.id}/${data?.id}`, null, { headers })
                .then((response) => {
                    console.log("Response : ", response?.data)
                    if (response?.data === true) {
                        toast.dark(`✅ ${data?.name} is added to WishList`, { closeOnClick: true })
                    } else {
                        toast.dark(`❌ ${data?.name} is not added to Wishlist. Please try again`, { closeOnClick: true })
                    }
                })
                .catch((err) => {
                    console.log("Error while adding item to wishlist: ", err)
                })
        } else {
            const routeLogin = window.confirm("Please login to add the product to your wishlist. Do you want to login?");
            console.log("routeLogin value:", routeLogin);
            if (routeLogin) {
                navigate('/login'); // Navigate to the login page
            }
        }


    }


    const fetchImage = () => {
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/rest/default/V1/products/${data?.sku}`)
        .then((response) => {
            setImageUrl(response?.data?.media_gallery_entries?.[0]?.file)
            setLoading(false)
        })
        .catch((err) => {
            console.log("err from fetchImages: ", err);
            setLoading(false)
        });
    }


    useEffect(() => {
        if (!data?.media_gallery_entries?.[0]?.file) {
            fetchImage()
        }
    },[])

    return (
        <div key={data?.id} className="flex flex-col justify-start items-start gap-2 border-2 rounded-md hover:shadow-xl p-2 md:w-[200px] w-[150px]" >
            <div className='w-full flex justify-center items-center relative'>
                <img src={`${process.env.REACT_APP_API_URL}/media/catalog/product${data?.media_gallery_entries?.[0]?.file ? data?.media_gallery_entries?.[0]?.file : imageUrl}`} alt="prod-img" width="100%" height="100%" className="object-contain md:max-h-[150px] md:h-[130px] sm:h-[100px] sm:w-[100px] md:w-40 h-[90px] w-[90px]"/>
                {!window?.location?.pathname.includes("wishlist") && (
                    <button className="absolute top-1 right-1" onClick={() => { addToWishlist() }}>
                        <AiOutlineHeart size={23} />
                    </button>
                )}
            </div>

            <div className="flex flex-col items-start justify-center md:px-3 px-1 py-1 gap-3 w-full">
                <p className="md:text-lg text-sm font-medium w-full truncate cursor-pointer font-lato" onClick={() => navigate(`/product/${data?.sku}/${data?.name?.replace(/ /g, "-")}`, { state: data })}>{data?.name}</p>
                <div className="w-full flex justify-between">
                    {data?.price && <p className="font-medium md:text-base text-sm">${data?.price}</p>}
                    {/* <p className="flex flex-row gap-1 md:text-base text-sm">
                        ⭐4.3
                    </p> */}
                </div>

                <div className="w-full flex justify-between">
                    <button className="text-xs hidden md:flex hover:bg-black bg-slate-800 justify-center items-center p-2 text-white cursor-pointer rounded-md" onClick={() => {
                    handleAddtoCart();
                }}>
                    ADD TO CART
                    </button>
                    <button className='md:hidden' onClick={() => {
                        handleAddtoCart();
                    }}> <BsFillCartPlusFill size={25} /></button>

                    <div className="flex gap-3">
                        <button onClick={() => { addToCompare() }}>
                            <BiBarChart size={23} />
                        </button>

                    </div>

                </div>


                {/* {cart?.some((prod) => prod?.id === data?.id) ? (
                    <div className='text-sm justify-between flex w-full items-center gap-4'>
                        <div className='px-2 py-1 border rounded-md w-2/3 bg-white text-black text-sm font-semibold flex xl:w-2/3 justify-between border-gray-400'>
                            <p className='font-semibold text-xl cursor-pointer' onClick={()=>handleDecrement()}>-</p>
                            <p className='font-semibold text-base'>{cart?.find((c) => c.sku === data?.sku)?.qty}</p>
                            <p className='font-semibold text-xl cursor-pointer' onClick={()=>handleIncrement()}>+</p>
                        </div>
                        <div className='w-1/3 flex justify-center items-center'>
                            <MdDelete size={32} className='cursor-pointer' onClick={() => {
                                dispatch({
                                    type: "REMOVE_FROM_CART",
                                    payload: {
                                        sku: data?.sku
                                    }
                                })
                            }}/>
                        </div>
                    </div>
                ) : (
                    <button className="text-sm bg-black flex justify-center items-center py-2 text-white cursor-pointer w-full" onClick={() => {
                        dispatch({
                            type: "ADD_TO_CART",
                            payload: data
                        })
                        handleAddtoCart();
                    }}>
                        ADD TO CART
                    </button>
                )} */}

            </div>
            <div className='font-outfit text-sm '>
                <ToastContainer position='bottom-left' autoClose={4000} type="dark" />
            </div>
        </div>
    )
}

export default ProductCard