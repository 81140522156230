import { cartReducer } from "./Reducer";
import { useContext, createContext, useReducer } from "react";


const Cart = createContext();

const Context = ({ children }) => {
    const [state, dispatch] = useReducer(cartReducer, {
      cart: [],
      compare: [],
      userData: {},
      secret: {}
    })
    
    return (
      <Cart.Provider value={{ state, dispatch}}>
        {children}
      </Cart.Provider>
    );
};

export default Context;

export const CartState = () => {
    return useContext(Cart)
}
