import React from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineCancelPresentation } from 'react-icons/md'
import { CartState } from "../../context/Context";
import usePageDurationTracker from "../../components/Hooks/usePageDurationTracker";



const ProductComparision = () => {
    const navigate = useNavigate()
    const { state: { compare }, dispatch } = CartState();
    let max = 4
  let totalProduct = max - compare?.length
  
  usePageDurationTracker(`Product Comparision`);
    return (
        <div className="prod-comparison-container p-3 font-outfit overflow-hidden overflow-x-scroll">
            <div className="flex ">
                <div className="flex flex-col  text-center gap-2 py-9 bg-[#00000080] justify-center items-center sm:w-1/5">
                    <h1 className="text-white">{compare?.length} Products Added</h1>
                    {totalProduct === 0 ? (<p>You can't add more than {max} Product</p>) : (<p>You can add {totalProduct} more Product</p>)}
                    <button className="p-2 bg-white border-2" onClick={() => navigate("/")} >Add Products+</button>
                </div>
                {
                    compare.map((data, index) => {
                        return (
                          <div
                            key={data?.id}
                            className=" flex flex-col mx-2 justify-start items-start gap-2 border-2  pb-4 hover:shadow-xl p-2 sm:w-1/5"
                          >
                            <div className="text-right">
                              <button
                                onClick={() => {
                                  dispatch({
                                    type: "REMOVE_FROM_COMPARE",
                                    payload: {
                                      sku: data?.sku,
                                    },
                                  });
                                }}
                              >
                                <MdOutlineCancelPresentation size={25} />
                              </button>
                            </div>
                            <div
                              className="w-full flex justify-center items-center"
                              onClick={() =>
                                navigate(
                                  `/product/${data?.sku}/${data?.name?.replace(
                                    / /g,
                                    "-"
                                  )}`,
                                  { state: data }
                                )
                              }
                            >
                              <img
                                src={`${process.env.REACT_APP_API_URL}/media/catalog/product${data?.media_gallery_entries?.[0]?.file}`}
                                alt="prod-img"
                                width="100%"
                                height="100%"
                                className="cursor-pointer object-contain max-h-80 w-40 bg-black"
                              />
                            </div>

                            <div className="flex flex-col items-start justify-center px-3 py-1 gap-2 w-full font-medium">
                              <p className="text-base overflow-hidden truncate ">
                                {data?.name}
                              </p>
                              <div className="w-full flex justify-between items-center">
                                <p className="md:text-base">${data?.price}</p>
                                <p className="flex flex-row gap-1 md:text-base">
                                  ⭐4.2
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                    })
                }
            </div>

            <div className="flex w-full">
                {
                    compare?.length !== 0 ? (
                        <div className="sm:w-1/5 flex  flex-col">
                            <div className="p-2 ml-3  ">SKU</div>
                            <div className="p-2 ml-3">Name</div>
                            <div className="p-2 ml-3">Price</div>
                            <div className="p-2 ml-3">Description</div>
                        </div>
                    ) : ""
                }
                {
                    compare.map((data) => {
                        return (
                            
                            <div key={data?.id} className="flex flex-col mx-2 text-center    pb-4  p-2 sm:w-1/5" >

                                <div className=' p-2 text-center' >
                                    {data.sku}
                                </div>
                                <div className="text-base overflow-hidden truncate ">{data?.name}</div>
                                <div className="p-2 text-center">${data?.price}</div>
                                <div className=" text-center">Perfect neutral shoe (tan) I have been looking for a neutral block heel sandal for months. I have ordered and sent back numerous pairs due to heel height, sizing issues, discomfort, etc. This heel height is perfect for everyday, great color for summer, and I love that the shoe is not suede or a leather covered heal.</div>
                            </div>


                        )
                    })}


            </div>
            {/* <div>
                <table class="table-auto w-full">

                    <tbody>
                        {
                            location?.state.map((data) => {
                                return (
                                    <tr>
                                        <td>{data.sku}</td>
                                        <td>{data.name}</td>
                                    </tr>
                                )
                            })}

                    </tbody>
                </table>
            </div> */}
        </div>
    )
}

export default ProductComparision