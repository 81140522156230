import axios from 'axios'
import React, { useState, useEffect } from 'react'
import Accordion from './FilterAccordian'
import SearchProductCard from './SearchProductCard'
import { BsArrowRightCircle } from 'react-icons/bs'
import { useLocation, useParams } from 'react-router-dom'
const SearchResults = () => {
    const location = useLocation();
    const { searchWord } = useParams()
    const [productList, setProductList] = useState([])
    const [searchList, setSearchList] = useState([])

    useEffect(() => {
        let listOfProduct = location?.state.filter((current) => current.type_id == 'simple')
        setProductList(listOfProduct)
        setSearchList(listOfProduct)
    }, [location])
    const [show, setShow] = useState(false)



    const priceFilter = (event) => {
        const filteredData = productList.filter((curr) =>
            curr.price <= event.target.value
        )
        setSearchList(filteredData)
    };

    return (
        <div className='w-full px-2 md:px-12'>
            <div className=" flex  flex-col md:flex-row items-start justify-center md:gap-5 font-outfit">

                <div className='w-full md:w-[20%]  mt-10 md:mt-20'>
                    <span className='w-full py-3 font-medium text-left  border-b border-gray-200 dark:border-gray-700 '>
                        Filters
                    </span>
                    <div id="accordion-flush" data-accordion="open" data-active-classes="bg-white text-gray-900" data-inactive-classes="text-gray-500 dark:text-gray-400">
                        <h2 id="accordion-flush-heading-1" onClick={() => setShow(!show)}>
                            <button type="button" className="flex items-center justify-between w-full py-3 font-medium text-left  border-b border-gray-700 " data-accordion-target="#accordion-flush-body-1" aria-expanded="true" aria-controls="accordion-flush-body-1">
                                <span>Price</span>
                                <p className={`${show && "rotate-180"} duration-500`}>+</p>
                            </button>
                        </h2>
                        <div id="accordion-flush-body-1" className={`${show ? 'block ' : 'hidden'} overflow-hidden duration-500 transition-all`} aria-labelledby="accordion-flush-heading-1">
                            <div className="py-5">
                                <form onChange={priceFilter}>

                                    <div className='flex gap-3 p-1'>
                                        <input type="radio" name="price" value="20" />
                                        <label>Under 20$</label>
                                    </div>
                                    <div className='flex gap-3 p-1'>
                                        <input type="radio" name="price" value="40" />
                                        <label>Below 40$</label>
                                    </div>

                                    <div className='flex gap-3 p-1'>
                                        <input type="radio" name="price" value="60" />
                                        <label>Below 60$</label>
                                    </div>

                                    <div className='flex gap-3 p-1'>
                                        <input type="radio" name="price" value="80" />
                                        <label>Below 80$</label>
                                    </div>

                                    <div className='flex gap-3 p-1'>
                                        <input type="radio" name="price" value="100" />
                                        <label>Below 100$</label>
                                    </div>

                                    <br />
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='p-2 w-full md:w-[75%]'>
                    <div className='py-2'>
                        <span className='py-2 text-2xl font-semibold'>Search Results- "{searchWord}"</span>

                    </div>
                    <div className='text-2xl'>{searchList?.length} Items</div>

                    <div className="flex flex-wrap md:gap-4 gap-1 gap-y-3 px-3 py-3 md:justify-start justify-center">

                        {
                            searchList?.length !== 0 ?
                                searchList.map((data, index) => {
                                    console.log("search card before data: ", data)
                                    return (
                                        <SearchProductCard data={data} key={index} />
                                    )
                                }) : (<p>No Search Results</p>)
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchResults;