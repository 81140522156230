import React, { useEffect, useState } from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import Address from '../components/Address/Address'
import Payment from '../components/Payment/Payment'
import OrderReview from '../components/OrderReview/OrderReview'
import { BsShieldLockFill, BsBagFill } from 'react-icons/bs'
import axios from 'axios'
import { CartState } from '../context/Context'
import { getCart } from "./../ReactFunctions";
import Cookies from 'universal-cookie'
import usePageDurationTracker from '../components/Hooks/usePageDurationTracker'



const CartSummaryCard = ({ data }) => {
    const {state: {cart}} = CartState()
    const [imageUrl, setImageUrl] = useState('') 
    const [loading, setLoading] = useState(false)
    const productsData = JSON.parse(localStorage?.getItem("products"))

    //fetching product images from localStorage
    const fetchImage = () => {
        setLoading(true)
        let req_prod = productsData?.find(prod => prod?.sku === data?.sku)
        setImageUrl(req_prod?.media_gallery_entries?.[0]?.file)
        setLoading(false)
    }


    useEffect(() => {
       fetchImage() 
    },[])

    return (
        <div className="flex h-[140px] items-center p-2 gap-3" key={data?.id} >
            <div className="mr-5">
                <img src={`${process.env.REACT_APP_API_URL}/media/catalog/product${imageUrl}`} className="w-24 h-24 " alt={data?.name} />
            </div>
            <div className="text-left flex flex-col gap-1">
                <p className="font-semibold text-base">{data?.name} </p>
            <div className='flex gap-3 items-center'>
                <p className="text-xs">Size: <span className='uppercase font-medium text-sm'>{data?.size ? data?.size : "XL"}</span></p>
                <p className="text-xs">Color: <span className='uppercase font-medium text-sm'>{data?.color? data?.color : "Gray"}</span></p>
            </div>
            <div className='flex gap-2 justify-start items-center'>
                <p className="text-base font-semibold">${data?.price}.00</p>
                <p className='text-xs'>X</p>
                <p className="text-base font-medium text-gray-600">{data?.qty}</p>
            </div>
            </div>
        </div>
    )
}



const Test = () => {
    const cookies = new Cookies()
    const navigate = useNavigate();
    const token = cookies?.get("auth_token")
    const location = useLocation();
    const [cartData, setCartData] = useState(location?.state ? location?.state : [])
    const [currentScreen, setCurrentScreen] = useState('address')
    const [selectedAddress, setSelectedAddress] = useState({})
    const [shippingCost, setShippingCost] = useState(null)
    const [discount, setDiscount] = useState(null)
    const [taxes, setTaxes] = useState(null)
    const subtotal = cartData?.reduce((total, item) => total + (item?.price * item?.qty), 0);
    const [orderTotal, setOrderTotal] = useState(null)
    const cartHeaders = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }

    useEffect(() => {
        if (cartData?.length === 0) {
            getCart(cartHeaders, setCartData)
        }
    }, [])
    
    usePageDurationTracker("Checkout");
    return (
    <>
        <div className="main-container w-full flex flex-col items-center justify-start p-6 gap-8 font-outfit">
            <div className="border-b-[1px] border-[#C7C7C7] w-5/6 py-3">
                <div className="flex items-center gap-2 justify-start">
                    <BsShieldLockFill className="w-5 h-5" />
                    <span className="text-xl px-1 text-[#2D2D2D] font-medium uppercase">Secure Checkout</span>
                </div>
            </div>
            <div className='w-full flex flex-col md:flex-row gap-4 justify-start items-start '>
                <div className='lg:w-3/5 md:w-1/2'>
                        {currentScreen === "address" && <Address currentScreen={setCurrentScreen} updateShippingCost={setShippingCost} updateTaxes={setTaxes} updateDiscount={setDiscount} updateTotal={setOrderTotal} />}
                        {currentScreen === "payment" && <Payment currentScreen={setCurrentScreen} total={orderTotal} />}
                    {currentScreen === "order" && <OrderReview currentScreen={setCurrentScreen}/>} 
                </div>
                
                <div className='lg:w-2/5 md:w-1/2 w-full p-2 border-2 border-gray-300 rounded-md flex flex-col gap-4'>
                    
                    <p className='flex gap-3 items-center p-3 px-2'>
                        <span><BsBagFill size={26}/></span>
                        <span className='font-bold text-lg'>Your Order</span>
                        </p>
                    <div className="flex flex-col gap-3 max-h-[420px] scrollbar-hide py-2 overflow-hidden overflow-y-scroll border-t-2 divide-y-2" >
                    {
                    cartData?.map((item, index) => {
                        return (
                            <CartSummaryCard data={item} key={index} />                            
                        )
                    })
                    }
                    </div>
                        {currentScreen === "payment"&& <div className="amount-container w-full flex flex-col py-4 gap-3">
                        <div className='p-2 flex justify-between items-center'>
                            <p className='font-medium'>Subtotal</p>
                            <p className='font-semibold'>₹{subtotal}.00</p>
                        </div>
                        {shippingCost !== null && <div className='p-2 flex justify-between items-center'>
                            <p className='font-medium'>Delivery</p>
                            <p className='font-semibold'>${shippingCost}.00</p>
                            </div>
                        }
                        {taxes !== null && <div className='p-2 flex justify-between items-center'>
                            <p className='font-medium'>Taxes & Duties</p>
                            <p className='font-semibold'>${taxes}.00</p>
                            </div>
                        }
                        {discount !== null && <div className='p-2 flex justify-between items-center'>
                            <p className='font-medium'>Discount</p>
                            <p className='font-semibold text-green-500'>- ${Math.abs(discount)}.00</p>
                            </div>
                        }
                        <div className='px-2 py-8 rounded-md flex justify-between items-center bg-[#fafafa]'>
                            <p className='font-bold text-xl'>Total</p>
                            <p className='font-bold text-xl'>${orderTotal}.00</p>
                        </div>
                    </div>}
                    
                        
                    

                        
                </div>
            </div>        
        </div>
    </>
      
  )
}

export default Test