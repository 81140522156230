import { useState } from "react";
import a from "./../../assets/images/a.png";
import debitcard from "./../../assets/images/cards.png"
import NetBanking from "./../../assets/images/netbanking.png";
import ApplePay from "./../../assets/images/ApplePay.png";
import PayPal from "./../../assets/images/PayPal.png";
import GPay from "./../../assets/images/gpay.png";
import PayonDelivery from "./../../assets/images/cod.png";
import crypto from "crypto-js";
import { CartState } from "../../context/Context";
import usePageDurationTracker from "../../components/Hooks/usePageDurationTracker";


const Payment = () => {
    const [showCardForm, setShowCardForm] = useState(false)
    const [selectedMethod, setSelectedMethod] = useState('')
    const { state: { userData }, dispatch } = CartState();

    const key = 'fy7PkC'
    const txnid = 't8svtqtjRdl4ki'
    const salt = '8X5uJsfLLWfbCTTuiVOmCHqiTJk1Muek'

    //for user information
    const firstName = "Ashish"
    const lastName = "kumar"
    const amount = "100"
    const productInfo = "iPhone"
    const email = "test@gmail.com"
    const successURL = "http://localhost:3000/success"
    const failureURL = "http://localhost:3000/failure"
    let upiHash = key + `|` + txnid + `|` + amount + `|` + productInfo + `|` + firstName + `|` + email + `|||||||||||` + salt
    const upiHashValue = crypto.SHA512(upiHash).toString()

    usePageDurationTracker("Payment");
    return (
        <div className="py-5 lg:mx-36 md:mx-16 sm:mx-8 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 grid gap-3 font-outfit">
            {/*Payment method starts*/}
            <div className="col-span-2 p-3">
                <div className="flex items-end pt-3 pb-1">
                    <img className="self-center px-1 w-7 h-auto max-w-full" src={a} alt="icon" />
                    <span className="text-xl px-2 text-[#4D4C4C]  font-normal">Payment Method</span>
                </div>
                <span className="text-base font-normal">Select a payment method and provide your payment details to complete the  Order.</span>
                <form>
                    <div className="border-2 border-[#606060] p-3 mt-5 rounded-lg flex justify-between bg-[#F0F2F6] cursor-pointer" onClick={() => { setShowCardForm(!showCardForm) }}>
                        <div className="flex ">
                            <img src={debitcard} alt="logo" className="px-1 h-auto max-w-full" />
                            <div className="px-2">
                                <h4 className="text-base font-medium">DEBIT/CREDIT CARD</h4>
                                <p className="text-sm text-[#606060]  font-normal">All type of cards accepted</p>
                            </div>
                        </div>
                        <div className="flex justify-center">
                            {/* <label className="self-center text-base text-black px-3">Free</label> */}
                            <input className="self-center checked:text-black-500 h-4 w-4 accent-black" type="radio" checked />
                        </div>
                    </div>
                    {showCardForm &&
                        <form action="">
                            <div className="grid lg:grid-cols-6 md:grid-cols-4 gap-3 my-3 p-3 ">
                                <div className="col-span-3">
                                    <label className="text-sm font-medium">ENTER CARD NUMBER</label>
                                    <input className="w-full px-3 py-2 border-[1px] border-[#C7C7C7] focus:outline-none" type="text" placeholder="XXXX-XXXX-XXXX-XXXX" required />
                                </div>

                                <div className="col-span-2">
                                    <label className="text-sm font-medium">EXPIRY DATE</label>
                                    <input className="w-full px-3 py-2 border-[1px] border-[#C7C7C7] focus:outline-none" type="text" placeholder="MM/YY" required />
                                </div>

                                <div className="col-span-1">
                                    <label className="text-sm font-medium">CVV</label>
                                    <input className="w-full px-3 py-2 border-[1px] border-[#C7C7C7] focus:outline-none" type="text" placeholder="CVV" required />
                                </div>
                            </div>
                            <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-3 p-1 px-3 ">
                                <div>
                                    <label className="text-sm font-medium">CARD HOLDER NAME</label>
                                    <input className="w-full px-3 py-2 border-[1px] border-[#C7C7C7] focus:outline-none" type="text" placeholder="Full Name" required />
                                </div>
                                <div>
                                    <button className="w-full px-3 py-2 flex justify-center items-center gap-2 bg-black text-white focus:outline-none lg:mt-[25px] md:mt-[25px] sm:mt-0">PAY <span className="font-medium">₹9,135</span></button>
                                </div>
                            </div>
                        </form>
                    }



                    <span className="text-[#5B5B5B]  font-normal text-xs px-3">Your card details will be securely saved for faster payments, your CVV will not be stored</span>

                    <div className="p-3 mt-5 rounded-lg flex justify-between bg-[#F0F2F6] cursor-pointer">
                        <div className="flex">
                            <img src={PayonDelivery} alt="logo" className="px-1 h-auto max-w-full" />
                            <div className="px-2">
                                <h4 className="text-base font-medium uppercase">Pay on delivery</h4>
                                <p className="text-sm text-gray-500">Pay with any mode of payment at the time of delivery</p>
                            </div>
                        </div>
                        <div className="flex justify-center">
                            {/* <label className="self-center text-xl font-normal  text-black px-3">Free</label> */}
                            <input className="self-center checked:text-black-500 h-4 w-4 accent-black" type="radio" />
                        </div>
                    </div>

                    <div className="p-3 mt-5 rounded-lg flex justify-between bg-[#F0F2F6] cursor-pointer">
                        <div className="flex">
                            <img src={NetBanking} alt="logo" className="px-1 h-auto max-w-full" />
                            <div className="px-2">
                                <h4 className="text-base font-medium uppercase">Net Banking</h4>
                                <p className="text-sm text-gray-500">Use internet banking from preferred bank to Pay</p>
                            </div>
                        </div>
                        <div className="flex justify-center">
                            {/* <label className="self-center text-xl font-normal  text-black px-3">Free</label> */}
                            <input className="self-center checked:text-black-500 h-4 w-4 accent-black" type="radio" />
                        </div>
                    </div>


                    <div class="flex items-center my-5">
                        <hr class="w-full border-t-2 border-[#F0F2F6]" />
                        <span class="text-base font-normal  px-2 text-black whitespace-nowrap">Express Checkout</span>
                        <hr class="w-full border-t-2 border-[#F0F2F6]" />
                    </div>

                    <div className="grid grid-cols-3 gap-3 mt-5">
                        <div className="p-1 bg-[#F0F2F6] flex justify-center cursor-pointer">
                            <img src={ApplePay} alt="logo" className="px-2 h-auto max-w-full" />
                            <h4 className="text-xl font-normal  text-black self-center">Apple Pay</h4>
                        </div>
                        <div className="p-1 bg-[#F0F2F6] flex justify-center cursor-pointer">
                            <img src={PayPal} alt="logo" className="px-2 h-auto max-w-full" />
                            <h4 className="text-xl font-normal  text-black self-center">PayPal</h4>
                        </div>
                        <div className="p-1 bg-[#F0F2F6] flex justify-center cursor-pointer">
                            <form action='https://test.payu.in/_payment' method='post'>
                                <input type="hidden" name="key" value={key} />
                                <input type="hidden" name="txnid" value={txnid} />
                                <input type="hidden" name="productinfo" value={productInfo} />
                                <input type="hidden" name="amount" value={amount} />
                                <input type="hidden" name="email" value={email} />
                                <input type="hidden" name="firstname" value={firstName} />
                                <input type="hidden" name="lastname" value={lastName} />
                                {/* <input type="hidden" name="pg" value="UPI" />
                                <input type="hidden" name="bankcode" value="UPI" />
                                <input type="hidden" name="vpa" value="test123@okhdfcbank" /> */}
                                <input type="hidden" name="surl" value={successURL} />
                                <input type="hidden" name="furl" value={failureURL} />
                                <input type="hidden" name="phone" value="9988776655" />
                                <input type="hidden" name="hash" value={upiHashValue} />
                                <input type="submit"
                                    className="text-xl   font-normal  text-black self-center"
                                    value="PAYU" />

                            </form>
                        </div>

                    </div>
                </form>

            </div>
            {/*Payment method ends*/}

            {/*order summary starts*/}
            <div className="p-3 bg-[#C7C7C7] h-fit">
                <h1>2nd div</h1>
            </div>
        </div>
    )
}

export default Payment;