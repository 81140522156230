import React from 'react'
import {MdCancel} from 'react-icons/md'

const Failure = () => {
  return (
    <div className="flex justify-center align-middle">
            <div className="p-5 flex flex-col items-center justify-start gap-2">
              <MdCancel color="red" size={48} />
                <h1 className="text-center text-2xl lg:text-4xl text-[#4D4C4C] font-sans font-semibold pb-2">Oh no! Something went wrong.</h1>
                <p className="text-center text-lg text-red-500 font-normal font-sans">we aren't able to process your payment. Please try again.</p>
                <div className="flex justify-center gap-3 py-7">
                    <button className="bg-black text-white py-2 rounded-md text-sm cursor-pointer lg:w-48 w-36  px-3">TRY AGAIN</button>
                    <button className="bg-white text-black py-2 rounded-md text-sm cursor-pointer lg:w-48 w-36  px-3 border-[1px] border-black">NO THANKS</button>
                </div>
                
            </div>
        </div>
  )
}

export default Failure