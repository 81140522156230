export const cartReducer = (state, action) => {
  switch (action.type) {
    case "ADD_TO_CART":
      return {
        ...state,
        cart: [...state.cart, { ...action.payload, qty: 1 }],
      };
    case "REMOVE_FROM_CART":
      return {
        ...state,
        cart: state.cart.filter((c) => c?.sku !== action.payload.id),
      };
    case "CHANGE_CART_QTY":
      return {
        ...state,
        cart: state.cart.filter((c) =>
          c.sku === action?.payload?.sku ? (c.qty = action?.payload?.qty) : c.qty
        ),
      };

    case "INCREMENT":
      return {
        ...state,
        cart: state.cart.filter((c) =>
          c.sku === action.payload.sku ? (c.qty += 1) : c.qty
        ),
      };
    case "DECREMENT":
      return {
        ...state,
        cart: state.cart.filter((c) =>
          c.sku === action.payload.sku ? (c.qty = c.qty - 1) : 1
        ),
      };
    case "CLEAR_CART":
      return {
        cart: [],
      };
    case "ADD_TO_COMPARE":
      console.log("dispatch to compare has been triggered");
      return {
        ...state,
        compare: [...state.compare, { ...action.payload }],
      };
    case "REMOVE_FROM_COMPARE":
      return {
        ...state,
        compare: state.compare.filter(
          (product) => product?.sku !== action?.payload?.sku
        ),
      };
    case "UPDATE_USER_DATA":
      return {
        ...state,
        userData: action?.payload?.data,
      };
    case "UPDATE_SECRET":
      console.log("dispatch to secret has been triggered", action?.payload);
      return {
        ...state,
        secret: action?.payload,
      };
    default:
      return state;
  }
};
