import React, { useEffect, useRef } from "react";
import axios from "axios";
import { useState } from "react";
import { BsFillBagFill, BsSearch, bsfillFa } from "react-icons/bs";
import { HiUserCircle } from "react-icons/hi";
import { MdFavorite } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { CartState } from "../../context/Context.js";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdOutlineFavoriteBorder } from "react-icons/md";
import Cookies from "universal-cookie";
import rudderanalytics from "../../rudderstack.js";
import rudderAnalytics from "../../rudderstack.js";
import Logo from '../../assets/images/ScketchLogo.png'

const Navbar = () => {
  const cookies = new Cookies();
  const {
    state: { cart, userData },
    dispatch,
  } = CartState();
  const navigate = useNavigate();
  const items_in_cart = cookies?.get("items_in_cart");
  const [items_count, setItems_count] = useState(items_in_cart ?? 0);
  const [categories, setCategories] = useState([]);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [searchWord, setSearchWord] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [isvisible, setVisible] = useState(false);
  const componentRef = useRef(null);
  const [showCategories, setShowCategories] = useState(false);
  const auth_token = cookies?.get("auth_token");
  const req_headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${auth_token}`,
  };


  const fetchCategories = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/rest/all/V1/categories`)
      .then((res) => {
        console.log("categories", res);
        setCategories(res.data.children_data[0].children_data);
      })
      .catch((err) => {
        console.log("Error fetching categories: ", err);
      });
  };

  const handleCategoryClick = (id, name) => {
    navigate(`/categories/${id}/${name}`);
  };

  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [hoveredSubCategory, setHoveredSubCategory] = useState(null);
  const [hoveredNestedCategory, setHoveredNestedCategory] = useState(null);

  const handleMouseEnter = (category) => {
    setHoveredCategory(category.id);
    setHoveredSubCategory(null);
    setHoveredNestedCategory(null);
  };

  const handleMouseLeave = () => {
    setHoveredCategory(null);
    setHoveredSubCategory(null);
    setHoveredNestedCategory(null);
  };

  const handleSubMouseEnter = (subCategory) => {
    setHoveredSubCategory(subCategory.id);
    setHoveredNestedCategory(null);
  };

  const handleSubMouseLeave = () => {
    setHoveredSubCategory(null);
    setHoveredNestedCategory(null);
  };

  const handleNestedMouseEnter = (nestedCategory) => {
    setHoveredNestedCategory(nestedCategory.id);
  };

  const handleNestedMouseLeave = () => {
    setHoveredNestedCategory(null);
  };

  const renderCategories = (categories) => {
    return (
      <ul className="flex w-full md:flex-row flex-col items-start md:items-center justify-start md:justify-evenly p-2 text-sm font-outfit">
        {categories.map((category) => (
          <li
            key={category.id}
            className="relative group"
            onMouseEnter={() => handleMouseEnter(category)}
            onMouseLeave={handleMouseLeave}
          >
            <p
              onClick={() => handleCategoryClick(category.id, category.name)}
              className="cursor-pointer  md:border-none text-white md:text-black hover:text-orange-400 font-semibold px-4 py-2"
            >
              <div className="flex flex-row  items-center gap-x-1">
                {category.name}
                {category.children_data &&
                  category.children_data.length > 0 && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6 w-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m19.5 8.25-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  )}
              </div>
            </p>
            {category.children_data && category.children_data.length > 0 && (
              <ul
                className={`absolute left-0 mt-0 bg-white border border-gray-200 rounded shadow-lg transition-all duration-300 ${
                  hoveredCategory === category.id ? "block" : "hidden"
                }`}
              >
                {renderSubCategories(category.children_data)}
              </ul>
            )}
          </li>
        ))}
      </ul>
    );
  };

  const renderSubCategories = (subCategories) => {
    return (
      <ul>
        {subCategories.map((subCategory) => (
          <li
            key={subCategory.id}
            className="relative group z-50"
            onMouseEnter={() => handleSubMouseEnter(subCategory)}
            onMouseLeave={handleSubMouseLeave}
          >
            <p
              onClick={() =>
                handleCategoryClick(subCategory.id, subCategory.name)
              }
              className="cursor-pointer z-50  hover:text-orange-400  border-b bg-white font-semibold px-4 py-2 whitespace-nowrap"
            >
              <div className="flex flex-row  items-center justify-between gap-x-1">
                <div>{subCategory.name}</div>
                <div>
                  {subCategory.children_data &&
                    subCategory.children_data.length > 0 && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6 w-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m19.5 8.25-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    )}
                </div>
              </div>
            </p>
            {subCategory.children_data &&
              subCategory.children_data.length > 0 && (
                <ul
                  className={`absolute left-full top-0 bg-white border border-gray-200 rounded shadow-lg transition-all duration-300 ${
                    hoveredSubCategory === subCategory.id ? "block" : "hidden"
                  }`}
                >
                  {renderNestedSubCategories(subCategory.children_data)}
                </ul>
              )}
          </li>
        ))}
      </ul>
    );
  };

  const renderNestedSubCategories = (nestedSubCategories) => {
    return (
      <ul>
        {nestedSubCategories.map((nestedSubCategory) => (
          <li
            key={nestedSubCategory.id}
            className="relative group z-50"
            onMouseEnter={() => handleNestedMouseEnter(nestedSubCategory)}
            onMouseLeave={handleNestedMouseLeave}
          >
            <p
              onClick={() =>
                handleCategoryClick(
                  nestedSubCategory.id,
                  nestedSubCategory.name
                )
              }
              className="cursor-pointer  hover:text-orange-400 z-50 border-b bg-white font-semibold px-4 py-2 whitespace-nowrap"
            >
              <div className="flex flex-row items-center gap-x-1">
                <div>{nestedSubCategory.name}</div>
                <div>
                  {nestedSubCategory.children_data &&
                    nestedSubCategory.children_data.length > 0 && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6 w-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m19.5 8.25-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    )}
                </div>
              </div>
            </p>
            {nestedSubCategory.children_data &&
              nestedSubCategory.children_data.length > 0 && (
                <ul
                  className={`absolute left-full z-50 top-0 bg-white border border-gray-200 rounded shadow-lg transition-all duration-300 ${
                    hoveredNestedCategory === nestedSubCategory.id
                      ? "block"
                      : "hidden"
                  }`}
                >
                  {renderNestedSubCategories(nestedSubCategory.children_data)}
                </ul>
              )}
          </li>
        ))}
      </ul>
    );
  };

  const handleSearch = () => {
    console.log("search result");
    setLoading(true);
    rudderAnalytics.track("Product Search", {
      searchKeyWord: searchWord,
    });
    if (searchWord?.length > 1) {

      axios
        .get(
          `${process.env.REACT_APP_API_URL}/rest/all/V1/products?searchCriteria[filter_groups][0][filters][0][field]=name&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][0][value]=%25${searchWord}%25`
        )
        .then((response) => {
          setSearchResults(response?.data?.items);
          console.log(response?.data?.items);
          setLoading(false);
          navigate(`/search-result/${searchWord}`, {
            state: response?.data?.items,
          });
          setSearchWord("");
        })
        .catch((error) => {
          console.log("Error while searching..", error);
          setLoading(false);
        });
    }
  };

  // const getItemsInCart = () => {
  //     console.log('cookies in navbar ran')
  //     return parseInt(cookies.get('items_in_cart')) || 0;
  // }

  const fetchUserData = () => {
    const url = `${process.env.REACT_APP_API_URL}/rest/V1/customers/me`;
    axios
      .get(url, { headers: req_headers })
      .then((response) => {
        dispatch({
          type: "UPDATE_USER_DATA",
          payload: {
            data: response?.data,
          },
        });
        console.log("Fetched user data: ", response?.data);
      })
      .catch((err) => {
        console.log(
          "error from fetching user Data: ",
          err?.response?.data?.message
        );
      });
  };


  
  useEffect(() => {
    setItems_count(items_in_cart);
  }, [items_in_cart]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        // Clicked outside the component, perform the desired action (e.g., close the component)
        setSearchResults([]);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (!userData?.firstname) {
      fetchUserData();
    }
  }, []);

  useEffect(() => {
    fetchCategories();
  }, []);
  const onclose = () => {
    setVisible(!isvisible);
  };
  return (
    <div className="w-full flex relative font-pop">
      <div className="flex-col hidden md:flex w-full ">
        <div className="flex justify-between px-16 py-5 items-center">
          <img
            src={Logo}
            alt="website=logo"
            height="100%"
            width="100%"
            className="h-[30px] w-[120px] object-contain cursor-pointer"
            onClick={() => navigate("/")}
          />
          <div className="flex flex-col px-2 py-1 w-1/2 items-center gap-2 rounded-sm relative font-outfit">
            <div className="flex px-2 py-1 overflow-hidden w-full items-center gap-2 border justify-between border-slate-600 rounded-md">
              <div className="flex items-center justify-start w-4/5">
                <BsSearch color="#141414" size={20} />
                <input
                  type="text"
                  value={searchWord}
                  onChange={(e) => setSearchWord(e?.target?.value)}
                  onKeyDown={(e) => e?.key === "Enter" && handleSearch()}
                  className=" border-none  w-4/5 px-2 outline-none py-2 text-sm md:text-base	"
                  placeholder="Search for products or brands..."
                />
              </div>
              <button
                onClick={() => handleSearch()}
                className="bg-gray-700 hover:bg-gray-900 text-white text-sm font-medium px-3 py-1 cursor-pointer outline-none rounded-sm"
              >
                Search
              </button>
            </div>
            {/* <div className="bg-white w-full top-10 z-10 mt-4 absolute rounded-md shadow-xl scrollbar-hide overflow-hidden overflow-y-scroll max-h-[360px] p-1" ref={componentRef}>
                            {searchResults?.map((result) => {
                                return (
                                    <div key={result?.sku} className="cursor-pointer py-2 px-3 flex gap-3 items-center hover:bg-slate-100">
                                        <img src={`http://65.0.150.104/media/catalog/product` + result?.custom_attributes?.[2]?.value} alt={result?.name} className="w-[40px] h-[40px] rounded-full" height="100%" width="100%" />
                                        <p>{result?.name}</p>
                                    </div>
                                )
                            })}
                        </div> */}
          </div>

          <div className="flex gap-5 items-center">
            <MdFavorite
              className="text-gray-700 hover:text-black cursor-pointer"
              size={22}
              onClick={() => {
                navigate("/wishlist");
              }}
            />
            <>
              <BsFillBagFill
                className="text-gray-700 hover:text-black cursor-pointer"
                size={22}
                onClick={() => navigate("/cart")}
              />
              {items_count > 0 && (
                <span className="absolute -mt-2.5 ml-14 rounded-[0.37rem] bg-red-600 px-[0.45em] py-[0.2em] text-[0.6rem] leading-none text-white">
                  {items_count}
                </span>
              )}
            </>
            <HiUserCircle
              className="text-gray-700 hover:text-black cursor-pointer"
              size={25}
              onClick={() => {
                setShowUserMenu(!showUserMenu);
                rudderanalytics.track("User button clicked", {
                  menuShown: showUserMenu,
                });
              }}
            />
            {showUserMenu &&
              (auth_token ? (
                <div
                  className="z-50 my-4 font-lato text-base w-[180px] list-none absolute top-12 right-16 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
                  id="user-dropdown"
                >
                  <div className="px-4 py-3">
                    <span className="block text-sm text-gray-900 dark:text-white font-bold">
                      {userData?.firstname + " " + userData?.lastname}
                    </span>
                    <span className="block text-xs font-medium text-gray-500 truncate dark:text-gray-400">
                      {userData?.email}
                    </span>
                  </div>
                  <ul
                    aria-labelledby="user-menu-button"
                    className="dark:divide-gray-600 py-2"
                  >
                    <li>
                      <p
                        className="block px-4 cursor-pointer py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                        onClick={() => {
                          setShowUserMenu(!showUserMenu);
                          navigate("/account");
                        }}
                      >
                        My Account
                      </p>
                    </li>
                    <li>
                      <p
                        className="block px-4 cursor-pointer py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                        onClick={() => {
                          setShowUserMenu(!showUserMenu);
                          navigate("/orders");
                        }}
                      >
                        Orders
                      </p>
                    </li>
                    <li>
                      <p
                        className="block px-4 cursor-pointer py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                        onClick={() => {
                          setShowUserMenu(!showUserMenu);
                          cookies.remove("auth_token");
                          cookies.remove("items_in_cart");
                          localStorage?.removeItem("cart_id")
                          setItems_count(0);
                          dispatch({
                            type: "UPDATE_USER_DATA",
                            payload: {
                              data: {},
                            },
                          });
                          navigate("/login");
                        }}
                      >
                        Sign out
                      </p>
                    </li>
                  </ul>
                </div>
              ) : (
                <div
                  className="z-50 my-4 text-base w-auto list-none absolute top-20 right-20 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
                  id="user-dropdown"
                >
                  <ul className="py-2" aria-labelledby="user-menu-button">
                    <li>
                      <p
                        className="block px-4 cursor-pointer py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                        onClick={() => {
                          setShowUserMenu(!showUserMenu);
                          navigate("/login");
                        }}
                      >
                        Login
                      </p>
                    </li>
                  </ul>
                </div>
              ))}
          </div>
        </div>
        {/* <div className="flex w-full flex-row justify-evenly p-2 text-sm font-outfit">
                    {
                        categories?.length !== 0 &&
                        categories.map((data, index) => {
                            return (
                                <p key={data?.id} className="cursor-pointer hover:text-orange-400 font-semibold" onClick={() => navigate(`/${url_words[index]}`)}>{data?.name}</p>
                            )
                        })
                    }
                </div> */}
        <nav className="bg-gray-100  ">{renderCategories(categories)}</nav>
      </div>
      <nav className="bg-gray-800 md:hidden w-full sticky z-10">
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
          <div className="relative flex h-16 items-center justify-between">
            <div className="absolute inset-y-0 gap-2 left-0 flex items-center sm:hidden">
              {/* <!-- Mobile menu button--> */}
              <button
                type="button"
                onClick={() => setShowCategories(!showCategories)}
                className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400"
              >
                <span className="absolute -inset-0.5"></span>
                <span className="sr-only">Open main menu</span>
                {/* <!--
                        Icon when menu is closed.

                        Menu open: "hidden", Menu closed: "block"
                    --> */}

                {showCategories ? (
                  <AiOutlineCloseSquare className="text-gray-300" size={28} />
                ) : (
                  <GiHamburgerMenu className="text-gray-300" size={28} />
                )}
              </button>
              <div
                className="flex flex-shrink-0 items-center"
                onClick={() => {
                  navigate("/");
                }}
              >
                <img
                  className="h-8 w-auto"
                  src="https://flowbite.com/docs/images/logo.svg"
                  alt="Your Company"
                  height="100%"
                  width="100%"
                />
              </div>
            </div>
            <div className="absolute inset-y-0 right-0 gap-x-3 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <button
                type="button"
                className="relative rounded-full bg-gray-800 p-1 text-gray-400"
                onClick={() => setVisible(!isvisible)}
              >
                <span className="absolute -inset-1.5"></span>
                <span className="sr-only">Search</span>
                <BsSearch
                  className="text-gray-300 hover:text-black cursor-pointer"
                  size={22}
                />
              </button>
              {isvisible ? (
                <SearchModel
                  onclose={onclose}
                  handleSearch={handleSearch}
                  searchWord={searchWord}
                  setSearchWord={setSearchWord}
                  searchResults={searchResults}
                />
              ) : (
                ""
              )}
              <button
                type="button"
                onClick={() => navigate("/cart")}
                className="relative rounded-full bg-gray-800 p-1 text-gray-400"
              >
                <span className="absolute -inset-1.5"></span>
                <span className="sr-only">View Cart</span>
                <BsFillBagFill
                  className="text-gray-300 hover:text-black cursor-pointer"
                  size={22}
                  onClick={() => navigate("/cart")}
                />
                {items_count > 0 && (
                  <span className="absolute -mt-6 ml-1 rounded-[0.37rem] bg-red-600 px-[0.45em] py-[0.2em] text-[0.6rem] leading-none text-white">
                    {items_count}
                  </span>
                )}
              </button>
              <MdOutlineFavoriteBorder
                className="text-gray-300  cursor-pointer"
                size={22}
                onClick={() => {
                  navigate("/wishlist");
                }}
              />
              {/* <!-- Profile dropdow    n --> */}
              <div className="relative">
                <div>
                  <button
                    type="button"
                    onClick={() => setShowUserMenu(!showUserMenu)}
                    className="relative flex rounded-full bg-gray-800 text-sm"
                  >
                    <span className="absolute -inset-1.5"></span>
                    <span className="sr-only">Open user menu</span>
                    <HiUserCircle
                      className="text-gray-300 hover:text-black cursor-pointer"
                      size={27}
                    />
                  </button>
                </div>

                {/* <!--
                        Dropdown menu, show/hide based on menu state.

                        Entering: "transition ease-out duration-100"
                        From: "transform opacity-0 scale-95"
                        To: "transform opacity-100 scale-100"
                        Leaving: "transition ease-in duration-75"
                        From: "transform opacity-100 scale-100"
                        To: "transform opacity-0 scale-95"
                    --> */}
                {showUserMenu &&
                  (auth_token ? (
                    <div
                      className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu-button"
                      tabIndex="-1"
                    >
                      {/* <!-- Active: "bg-gray-100", Not Active: "" --> */}
                      <p
                        onClick={() => navigate("/account")}
                        className="block px-4 py-2 text-sm text-gray-700"
                        role="menuitem"
                        tabIndex="-1"
                        id="user-menu-item-0"
                      >
                        My Account
                      </p>
                      <p
                        onClick={() => navigate("/orders")}
                        className="block px-4 py-2 text-sm text-gray-700"
                        role="menuitem"
                        tabIndex="-1"
                        id="user-menu-item-2"
                      >
                        Orders
                      </p>
                      <p
                        onClick={() => {
                          cookies.remove("auth_token");
                          setShowUserMenu(!showUserMenu);
                          dispatch({
                            type: "UPDATE_USER_DATA",
                            payload: {
                              data: {},
                            },
                          });
                          navigate("/login");
                        }}
                        className="block px-4 py-2 text-sm text-gray-700"
                        role="menuitem"
                        tabIndex="-1"
                        id="user-menu-item-2"
                      >
                        Sign out
                      </p>
                    </div>
                  ) : (
                    <div
                      className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu-button"
                      tabIndex="-1"
                    >
                      <p
                        onClick={() => {
                          setShowUserMenu(!showUserMenu);
                          navigate("/login");
                        }}
                        className="block px-4 py-2 text-sm text-gray-700"
                        role="menuitem"
                        tabIndex="-1"
                        id="user-menu-item-2"
                      >
                        Login
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Mobile menu, show/hide based on menu state. --> */}
        {showCategories && (
          <div className="sm:hidden" id="mobile-menu">
            <div className="space-y-1 px-2 pb-3 pt-2">
              <nav className="">{renderCategories(categories)}</nav>
              {/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" --> */}
              {/* {
                            categories?.length !== 0 &&
                            categories.map((data, index) => {
                                return (
                                    <p key={data?.id} className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium" onClick={() => navigate(`/${url_words[index]}`)}>{data?.name}</p>
                                )
                            })
                        } */}
              {/* <a href="#" className="bg-gray-900 text-white block rounded-md px-3 py-2 text-base font-medium" aria-current="page">Dashboard</a>
                <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Team</a>
                <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Projects</a>
                <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Calendar</a> */}
            </div>
          </div>
        )}
      </nav>
    </div>
  );
};

const SearchModel = ({
  onclose,
  handleSearch,
  searchWord,
  setSearchWord,
  searchResults,
}) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-md flex justify-center items-start py-20">
      <div className="w-[95%] min-h-[300px] rounded-md flex flex-col gap-4 px-5 bg-white p-2">
        <button
          className="text-white text-lg place-self-end w-[35px] h-[35px] rounded-md bg-gray-700 font-medium"
          onClick={() => onclose()}
        >
          X
        </button>
        <div className="flex px-2 py-1 overflow-hidden bg-white w-full items-center gap-2 border justify-between border-slate-600 rounded-md mt-5">
          <div className="flex items-center justify-start w-4/5">
            <BsSearch color="#141414" size={20} />
            <input
              type="text"
              className=" border-none  w-4/5 px-2 outline-none py-2 text-sm md:text-base"
              placeholder="Search for products or brands..."
              value={searchWord}
              onChange={(e) => setSearchWord(e?.target?.value)}
              onKeyDown={(e) => e?.key === "Enter" && handleSearch()}
            />
          </div>
          <button
            onClick={() => {
              handleSearch();
              onclose();
            }}
            className="bg-gray-700 hover:bg-gray-900 text-white text-sm font-medium px-3 py-1 cursor-pointer outline-none rounded-sm"
          >
            Search
          </button>
        </div>
        {/* <div className=" w-full top-10 z-10   rounded-md shadow-xl scrollbar-hide overflow-hidden overflow-y-scroll max-h-[360px] p-1" >
                    {searchResults?.map((result) => {
                        return (
                            <div key={result?.sku} className=" bg-white cursor-pointer py-2 px-3 flex gap-3 items-center hover:bg-slate-100">
                                <img src={`http://65.0.150.104/media/catalog/product${result?.media_gallery_entries?.[0]?.file}`} alt={result?.name} className="w-[40px] h-[40px] rounded-full" height="100%" width="100%" />
                                <p>{result?.name}</p>
                            </div>
                        )
                    })}
                </div> */}
      </div>
    </div>
  );
};
export default Navbar;

// old code

// import React, { useEffect, useRef } from "react";
// import axios from "axios";
// import { useState } from "react";
// import { BsFillBagFill, BsSearch, bsfillFa } from 'react-icons/bs'
// import { HiUserCircle } from 'react-icons/hi'
// import { MdFavorite } from 'react-icons/md'
// import { useNavigate } from "react-router-dom";
// import { CartState } from "../../context/Context.js";
// import { AiOutlineCloseSquare } from 'react-icons/ai'
// import { GiHamburgerMenu } from 'react-icons/gi'
// import { MdOutlineFavoriteBorder } from 'react-icons/md'
// import Cookies from 'universal-cookie'

// const Navbar = () => {
//     const cookies = new Cookies()
//     const { state: { cart, userData }, dispatch } = CartState();
//     const navigate = useNavigate()
//     // const items_in_cart  = cookies?.get("items_in_cart")
//     // const [items_count, setItems_count] = useState(items_in_cart)
//     const items_in_cart = cookies?.get("items_in_cart");
// const initialItemsCount = items_in_cart ? items_in_cart : 0;
// const [items_count, setItems_count] = useState(initialItemsCount);

//     const [categories, setCategories] = useState([]);
//     const [showUserMenu, setShowUserMenu] = useState(false)
//     const [searchWord, setSearchWord] = useState("")
//     const [loading, setLoading] = useState(false)
//     const [searchResults, setSearchResults] = useState([])
//     const [isvisible, setVisible] = useState(false)
//     const componentRef = useRef(null);
//     const [showCategories, setShowCategories] = useState(false)
//     const auth_token = cookies?.get("auth_token")
//     const req_headers = {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${auth_token}`,
//     };

//     const url_words = ["new_arrivals", "women", "men", "gear", "training", "collections", "promotions", "sale"]
//     const fetchCategories = () => {
//         axios
//             .get("http://65.0.150.104/rest/all/V1/categories")
//             .then((res) => {
//                 setCategories(res.data.children_data[0].children_data)
//             })
//             .catch((err) => {
//                 console.log("Error fetching categories: ", err)
//             })
//     }

//     const handleSearch = () => {
//         setLoading(true)
//         if (searchWord?.length > 1) {
//             axios.get(`http://65.0.150.104/rest/all/V1/products?searchCriteria[filter_groups][0][filters][0][field]=name&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][0][value]=%25${searchWord}%25`)
//                 .then((response) => {
//                     setSearchResults(response?.data?.items)
//                     setLoading(false)
//                 })
//                 .catch((error) => {
//                     console.log("Error while searching..", error)
//                     setLoading(false)
//                 })
//         }
//     }

//     // const getItemsInCart = () => {
//     //     console.log('cookies in navbar ran')
//     //     return parseInt(cookies.get('items_in_cart')) || 0;
//     // }

//     const fetchUserData = () => {
//         const url = "http://65.0.150.104/rest/V1/customers/me"
//         axios.get(url, { headers: req_headers })
//             .then((response) => {
//                 dispatch({
//                     type: "UPDATE_USER_DATA",
//                     payload: {
//                         data: response?.data,
//                     },
//                 });
//                 console.log("Fetched user data: ", response?.data);
//             })
//             .catch((err) => {
//                 console.log(
//                     "error from fetching user Data: ",
//                     err?.response?.data?.message
//                 );
//             });
//     }

//     // const fetchUserData = () => {
//     //     const url = "http://65.0.150.104/rest/V1/customers/me"
//     //     axios.get(url, { headers: address_headers })
//     //     .then((response) => {
//     //         dispatch({
//     //         type: "UPDATE_USER_DATA",
//     //         payload: {
//     //         data: response?.data
//     //         }
//     //     })
//     //         console.log("Fetched user data: ", response?.data)
//     //     })
//     //     .catch((err) => {
//     //     console.log('error from fetching user Data: ', err?.response?.data?.message)
//     //     })
//     // }

//     useEffect(() => {
//         setItems_count(items_in_cart)
//     },[items_in_cart])

//     useEffect(() => {
//         const handleOutsideClick = (event) => {
//             if (componentRef.current && !componentRef.current.contains(event.target)) {
//                 // Clicked outside the component, perform the desired action (e.g., close the component)
//                 setSearchResults([])
//             }
//         };

//         document.addEventListener('mousedown', handleOutsideClick);

//         return () => {
//             document.removeEventListener('mousedown', handleOutsideClick);
//         };
//     }, []);

//     useEffect(() => {
//         if (!userData?.firstname) {
//             fetchUserData()
//         }
//     }, [])

//     useEffect(() => {
//         fetchCategories();
//     }, [])
//     const onclose = () => {
//         setVisible(!isvisible)
//     }
//     return (
//         <div className="w-full flex relative">
//             <div className="flex-col hidden md:flex w-full p-4">
//                 <div className="flex justify-between px-16 py-5 items-center">
//                     <p onClick={() => navigate("/")} className="font-semibold text-xl cursor-pointer py-2 px-8 bg-[#D9D9D9]">LOGO</p>
//                     <div className="flex flex-col px-2 py-1 w-1/2 items-center gap-2 rounded-sm relative font-outfit">
//                         <div className="flex px-2 py-1 overflow-hidden w-full items-center gap-2 border justify-between border-slate-600 rounded-md">
//                             <div className="flex items-center justify-start w-4/5">
//                                 <BsSearch color="#141414" size={20} />
//                                 <input type="text" value={searchWord} onChange={(e) => setSearchWord(e?.target?.value)} onKeyDown={(e) => e?.key === "Enter" && handleSearch()} className=" border-none  w-4/5 px-2 outline-none py-2 text-sm md:text-base	" placeholder="Search for products or brands..." />
//                             </div>
//                             <button onClick={() => handleSearch()} className="bg-gray-700 hover:bg-gray-900 text-white text-sm font-medium px-3 py-1 cursor-pointer outline-none rounded-sm">Search</button>
//                         </div>
//                         <div className="bg-white w-full top-10 z-10 mt-4 absolute rounded-md shadow-xl scrollbar-hide overflow-hidden overflow-y-scroll max-h-[360px] p-1" ref={componentRef}>
//                             {searchResults?.map((result) => {
//                                 return (
//                                     <div key={result?.sku} className="cursor-pointer py-2 px-3 flex gap-3 items-center hover:bg-slate-100">
//                                         <img src={`http://65.0.150.104/media/catalog/product` + result?.custom_attributes?.[2]?.value} alt={result?.name} className="w-[40px] h-[40px] rounded-full" height="100%" width="100%" />
//                                         <p>{result?.name}</p>
//                                     </div>
//                                 )
//                             })}
//                         </div>
//                     </div>

//                     <div className="flex gap-5 items-center">
//                         <MdFavorite className="text-gray-700 hover:text-black cursor-pointer" size={22} onClick={() => { navigate("/wishlist") }} />
//                        {/* //whishlist */}
//                         <>
//                             <BsFillBagFill className="text-gray-700 hover:text-black cursor-pointer" size={22} onClick={() => navigate("/cart")} />
//                             {items_count > 0 && <span className="absolute -mt-2.5 ml-14 rounded-[0.37rem] bg-red-600 px-[0.45em] py-[0.2em] text-[0.6rem] leading-none text-white">{items_count}</span>}
//                         </>
//                         <HiUserCircle className="text-gray-700 hover:text-black cursor-pointer" size={25} onClick={() => setShowUserMenu(!showUserMenu)} />
//                         {showUserMenu && (
//                             auth_token ? (
//                                 <div className="z-50 my-4 text-base w-auto list-none absolute top-20 right-20 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600" id="user-dropdown">
//                                     <div className="px-4 py-3">
//                                         <span className="block text-sm text-gray-900 dark:text-white font-medium">{userData?.firstname + " " + userData?.lastname}</span>
//                                         <span className="block text-xs font-medium text-gray-500 truncate dark:text-gray-400">{userData?.email}</span>
//                                     </div>
//                                     <ul className="py-2" aria-labelledby="user-menu-button">
//                                         <li>
//                                             <p className="block px-4 cursor-pointer py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white" onClick={() => {
//                                                 setShowUserMenu(!showUserMenu)
//                                                 navigate("/profile")
//                                             }}>Profile</p>
//                                         </li>
//                                         <li>
//                                             <p className="block px-4 cursor-pointer py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white" onClick={() => {
//                                                 setShowUserMenu(!showUserMenu)
//                                                 navigate("/settings")
//                                             }}>Settings</p>
//                                         </li>
//                                         <li>
//                                             <p className="block px-4 cursor-pointer py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white" onClick={() => {
//                                                 setShowUserMenu(!showUserMenu)
//                                                 navigate("/orders")
//                                             }}>Orders</p>
//                                         </li>
//                                         <li>
//                                             <p className="block px-4 cursor-pointer py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white" onClick={() => {
//                                                 setShowUserMenu(!showUserMenu)
//                                                 cookies.remove("auth_token")
//                                                 dispatch({
//                                                     type: "UPDATE_USER_DATA",
//                                                     payload: {
//                                                         data: {}
//                                                     }
//                                                 })
//                                                 navigate("/login")
//                                             }}>Sign out</p>
//                                         </li>
//                                     </ul>
//                                 </div>
//                             ) : (<div className="z-50 my-4 text-base w-auto list-none absolute top-20 right-20 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600" id="user-dropdown">
//                                 <ul className="py-2" aria-labelledby="user-menu-button">
//                                     <li>
//                                         <p className="block px-4 cursor-pointer py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white" onClick={() => {
//                                             setShowUserMenu(!showUserMenu)
//                                             navigate("/login")
//                                         }}>Login</p>
//                                     </li>
//                                 </ul>
//                             </div>)

//                         )}
//                     </div>
//                 </div>
//                 <div className="flex w-full flex-row justify-evenly p-2 text-sm font-outfit">
//                     {
//                         categories?.length !== 0 &&
//                         categories.map((data, index) => {
//                             return (
//                                 <p key={data?.id} className="cursor-pointer hover:text-orange-400 font-semibold" onClick={() => navigate(`/${url_words[index]}`)}>{data?.name}</p>
//                             )
//                         })
//                     }
//                 </div>
//             </div>
//             <nav className="bg-gray-800 md:hidden w-full sticky z-10">
//                 <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
//                     <div className="relative flex h-16 items-center justify-between">
//                         <div className="absolute inset-y-0 gap-2 left-0 flex items-center sm:hidden">
//                             {/* <!-- Mobile menu button--> */}
//                             <button type="button" onClick={() => setShowCategories(!showCategories)} className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400">
//                                 <span className="absolute -inset-0.5"></span>
//                                 <span className="sr-only">Open main menu</span>
//                                 {/* <!--
//                         Icon when menu is closed.

//                         Menu open: "hidden", Menu closed: "block"
//                     --> */}

//                     {showCategories ? (<AiOutlineCloseSquare className="text-gray-300" size={28}/>) : (<GiHamburgerMenu className="text-gray-300" size={28}/>  )}
//                     </button>
//                     <div className="flex flex-shrink-0 items-center" onClick={()=>{navigate("/")}}>
//                     <img className="h-8 w-auto" src="https://flowbite.com/docs/images/logo.svg" alt="Your Company" />
//                     </div>
//                 </div>
//                     <div className="absolute inset-y-0 right-0 gap-x-3 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
//                         <button type="button" className="relative rounded-full bg-gray-800 p-1 text-gray-400"
//                          onClick={()=>setVisible(!isvisible)}
//                         >
//                             <span className="absolute -inset-1.5"></span>
//                             <span className="sr-only">Search</span>
//                             <BsSearch className="text-gray-300 hover:text-black cursor-pointer" size={22} />
//                         </button>
//                             {isvisible ? <SearchModel onclose={onclose} handleSearch={handleSearch} searchWord={searchWord} setSearchWord={setSearchWord} searchResults={searchResults} /> :""}
//                             <button type="button" onClick={()=>navigate("/cart")}className="relative rounded-full bg-gray-800 p-1 text-gray-400">
//                         <span className="absolute -inset-1.5"></span>
//                         <span className="sr-only">View Cart</span>
//                         <BsFillBagFill className="text-gray-300 hover:text-black cursor-pointer" size={22} onClick={() => navigate("/cart")} />
//                         {items_count > 0 && <span className="absolute -mt-6 ml-1 rounded-[0.37rem] bg-red-600 px-[0.45em] py-[0.2em] text-[0.6rem] leading-none text-white">{items_count}</span>}
//                     </button>
//                     <MdOutlineFavoriteBorder className="text-gray-300  cursor-pointer" size={22} onClick={()=>{navigate("/wishlist")}} />
//                     {/* <!-- Profile dropdow    n --> */}
//                     <div className="relative">
//                     <div>
//                         <button type="button" onClick={()=>setShowUserMenu(!showUserMenu)} className="relative flex rounded-full bg-gray-800 text-sm">
//                         <span className="absolute -inset-1.5"></span>
//                         <span className="sr-only">Open user menu</span>
//                         <HiUserCircle className="text-gray-300 hover:text-black cursor-pointer" size={27} />
//                         </button>
//                     </div>

//                                 {/* <!--
//                         Dropdown menu, show/hide based on menu state.

//                         Entering: "transition ease-out duration-100"
//                         From: "transform opacity-0 scale-95"
//                         To: "transform opacity-100 scale-100"
//                         Leaving: "transition ease-in duration-75"
//                         From: "transform opacity-100 scale-100"
//                         To: "transform opacity-0 scale-95"
//                     --> */}
//                     {showUserMenu && (auth_token ? (
//                             <div className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabIndex="-1">
//                         {/* <!-- Active: "bg-gray-100", Not Active: "" --> */}
//                             <p onClick={() => navigate('/profile')} className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex="-1" id="user-menu-item-0">Your Profile</p>
//                             <p onClick={()=>navigate('/orders')} className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex="-1" id="user-menu-item-2">Orders</p>
//                             <p onClick={()=>navigate('/settings')} className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex="-1" id="user-menu-item-1">Settings</p>
//                             <p onClick={() => {
//                                 cookies.remove("auth_token")
//                                 setShowUserMenu(!showUserMenu)
//                                 dispatch({
//                                     type: "UPDATE_USER_DATA",
//                                     payload: {
//                                     data: {}
//                                     }
//                                 })
//                                 navigate('/login')
//                             }} className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex="-1" id="user-menu-item-2">Sign out</p>
//                             </div>
//                     ) : (
//                         <div className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabIndex="-1">
//                             <p onClick={()=>{setShowUserMenu(!showUserMenu)
//                                         navigate("/login")}} className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex="-1" id="user-menu-item-2">Login</p>
//                         </div>
//                                 )
//                     )
//                     }
//                     </div>
//                         </div>
//                     </div>
//                     </div>

//                 {/* <!-- Mobile menu, show/hide based on menu state. --> */}
//                 {showCategories && <div className="sm:hidden" id="mobile-menu">
//                     <div className="space-y-1 px-2 pb-3 pt-2">
//                         {/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" --> */}
//                         {
//                             categories?.length !== 0 &&
//                             categories.map((data, index) => {
//                                 return (
//                                     <p key={data?.id} className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium" onClick={() => navigate(`/${url_words[index]}`)}>{data?.name}</p>
//                                 )
//                             })
//                         }
//                         {/* <a href="#" className="bg-gray-900 text-white block rounded-md px-3 py-2 text-base font-medium" aria-current="page">Dashboard</a>
//                 <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Team</a>
//                 <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Projects</a>
//                 <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Calendar</a> */}
//                     </div>
//                 </div>}
//             </nav>

//         </div>

//     )
// }

// const SearchModel = ({ onclose, handleSearch, searchWord, setSearchWord, searchResults }) => {

//     return (
//         <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-md flex justify-center items-start py-20">
//             <div className="w-[95%] min-h-[300px] rounded-md flex flex-col gap-4 px-5 bg-white p-2">
//                 <button className="text-white text-lg place-self-end w-[35px] h-[35px] rounded-md bg-gray-700 font-medium" onClick={() => onclose()}>X</button>
//                 <div className="flex px-2 py-1 overflow-hidden bg-white w-full items-center gap-2 border justify-between border-slate-600 rounded-md mt-5">
//                     <div className="flex items-center justify-start w-4/5">
//                         <BsSearch color="#141414" size={20} />
//                         <input type="text"
//                             className=" border-none  w-4/5 px-2 outline-none py-2 text-sm md:text-base"
//                             placeholder="Search for products or brands..."
//                             value={searchWord}
//                             onChange={(e) => setSearchWord(e?.target?.value)}
//                             onKeyDown={(e) => e?.key === "Enter" && handleSearch()}
//                         />
//                     </div>
//                     <button onClick={() => handleSearch()}
//                     className="bg-gray-700 hover:bg-gray-900 text-white text-sm font-medium px-3 py-1 cursor-pointer outline-none rounded-sm">Search</button>
//                 </div>
//                 <div className=" w-full top-10 z-10  rounded-md shadow-xl scrollbar-hide overflow-hidden overflow-y-scroll max-h-[360px] p-1" >
//                     {searchResults?.map((result) => {
//                         return (
//                             <div key={result?.sku} className=" bg-white cursor-pointer py-2 px-3 flex gap-3 items-center hover:bg-slate-100">
//                                 <img src={`http://65.0.150.104/media/catalog/product${result?.media_gallery_entries?.[0]?.file}`} alt={result?.name} className="w-[40px] h-[40px] rounded-full" height="100%" width="100%" />
//                                 <p>{result?.name}</p>
//                             </div>
//                         )
//                     })}
//                 </div>
//             </div>
//         </div>
//     )
// }
// export default Navbar
