import React, {useEffect, useState} from 'react'
import axios from 'axios'
import OAuth from 'oauth-1.0a';
import { CartState } from '../../context/Context';
import { HmacSHA256, enc } from 'crypto-js'; 
import { useNavigate } from 'react-router-dom';
import { SecretsManagerClient } from "@aws-sdk/client-secrets-manager";
import { fetchProducts } from '../../ReactFunctions';
import usePageDurationTracker from '../../components/Hooks/usePageDurationTracker';


export const OrderItemCard = ({ product }) => {
    const [imageUrl, setImageUrl] = useState('')
    const [loading, setLoading] = useState(false)
    const productsData = JSON.parse(localStorage?.getItem("products"))


    const fetchImage = () => {
        setLoading(true)
        let req_prod = productsData?.find(prod => prod?.sku === product?.sku)
        setImageUrl(req_prod?.media_gallery_entries?.[0]?.file)
        setLoading(false)
    }

    // const fetchImage = () => {
    //     setLoading(true)
    //     axios.get(`http://65.0.150.104/rest/default/V1/products/${product?.sku}`)
    //     .then((response) => {
    //         console.log('response from fetch img: ', response?.data)
    //         setImageUrl(response?.data?.media_gallery_entries?.[0]?.file)
    //         setLoading(false)
    //     })
    //     .catch((err) => {
    //         console.log("err from fetchImages: ", err);
    //         setLoading(false)
    //     });
    // }

    useEffect(() => {
       fetchImage() 
    },[])
    return (
        <div className="w-full md:py-6 py-3 flex justify-between items-start" key={[product?.id]}>
            <div className="flex gap-3">
                <div>
                    {loading ? (<div className='rounded-md bg-[#ECF2F4] w-[100px] h-[100px] loading-container p-4 flex justify-center items-center'>
                        
                  <svg aria-hidden="true" className="w-5 h-5 mr-2 text-gray-200 animate-spin  fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                  </svg>
           
                    </div>)  : (<img className="rounded-md object-contain md:w-[100px] md:h-[100px] w-[80px] h-[80px]" src={`${process.env.REACT_APP_API_URL}/media/catalog/product${imageUrl}`} alt="prod-img" />)}
                    
                </div>
                <div className="flex flex-col gap-3">
                    <p className="font-medium">{product?.name}</p>
                    <p className=""><span className="text-gray-400 font-medium">Qty: </span>{product?.qty_ordered}</p>
                    <p className="capitalize">{product?.product_type}</p>
                </div>
            </div>
            <div  className="font-medium">${product?.row_total === 0 ? product?.parent_item?.base_row_total : product?.row_total }</div>
        </div>
    )
}


const OrderCard = ({ order }) => {
    const navigate = useNavigate()
    
    return (
        <div className="order-container w-full" key={order?.entity_id}>
            <div className="order-info-container w-full flex items-center justify-between border-b border-gray-300 py-3">
                <div className="order-info flex flex-col md:flex-row md:items-center md:justify-start items-start justify-center md:gap-10 gap-3 md:w-3/4 w-full">
                    <p className="flex gap-2 items-baseline hover:underline cursor-pointer" onClick={() => navigate(`/order/${order?.entity_id}`, { state: order })}><span className="text-gray-500 text-sm">OrderID: </span><span className="font-medium">#{order?.increment_id}</span></p>
                    <p className="flex gap-2 items-baseline"><span className="text-gray-500 text-sm">Date: </span><span className="font-medium">{order?.created_at?.split(" ")[0]}</span></p>
                    <p className="flex gap-2 items-baseline"><span className="text-gray-500 text-sm">Order status: </span><span className="font-medium capitalize">{order?.status}</span></p>
                </div>
                <div className="flex">
                    {/* <button className="p-2 md:display hidden px-4 rounded-md bg-gray-100 hover:bg-gray-200 delay-400 text-sm font-medium">View Invoice</button> */}
                </div>
            </div>
            <div className="flex flex-col gap-4">
                {order?.items?.map((product, index) => {
                    if (product?.product_type !== "configurable") {
                        return (
                                <OrderItemCard product={product}/>
                            // <div className="w-full py-6 flex justify-between items-start" key={index}>
                            //     <div className="flex gap-3">
                            //         <div>
                            //             <img className="rounded-md object-contain w-[100px] h-[100px]" src={default_image} alt="prod-img" />
                            //         </div>
                            //         <div className="flex flex-col gap-3">
                            //             <p className="font-medium">{product?.name}</p>
                            //             <p className=""><span className="text-gray-400 font-medium">Qty: </span>{product?.qty_ordered}</p>
                            //             <p className="capitalize">{product?.product_type}</p>
                            //         </div>
                            //     </div>
                            //     <div  className="font-medium">${product?.row_total === 0 ? product?.parent_item?.base_row_total : product?.row_total }</div>
                            // </div>
                        )
                    } else {
                        return null
                    }
                    
                })}      
            </div>
                                    
        </div>
    )
}

const Orders = () => {
    const { state: { userData, secret }, dispatch } = CartState();
    const navigate = useNavigate();
    const [orders, setOrders] = useState([])
    const [allOrders, setAllOrders] = useState([])
    const [loading, setLoading] = useState(false)
    // const [fetchedSecretKey, setFetchedSecretKey] = useState(false)
    // const [secretKey, setSecretKey] = useState({})
    // const consumerKey = 'e17nkc2s9rshf6ugsgz8edu95d6wjdg3';
    // const consumerSecret = 'ezyfqs5t1rru7ilcm3xoxzcutezbf6sf';
    // const accessToken = 'u48ubgk55kld1airlury0nv38qe84qy3';
    // const accessTokenSecret = '40wgxau86ufg9h6vrbnpuus86pwq10tu';
    // const default_image = "https://cdn.rareblocks.xyz/collection/clarity-ecommerce/images/order-confirmation/3/product-1.png"

    const url = `${process.env.REACT_APP_API_URL}/rest/default/V1/orders/?searchCriteria[filter_groups][0][filters][0][field]=customer_id&searchCriteria[filter_groups][0][filters][0][value]=${userData?.id}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq`; // Replace this with the actual API endpoint URL

    const oauth = OAuth({
        consumer: {
            key: secret?.consumerKey,
            secret: secret?.consumerSecret,
        },
        signature_method: 'HMAC-SHA256',
        hash_function(base_string, key) {
            return HmacSHA256(base_string, key).toString(enc.Base64); // Use HmacSHA256 from crypto-js
        },
    });

    const secret_name = "Magento_Admin_Oauth";

    const client = new SecretsManagerClient({
    region: "ap-south-1",
    });




//     const fetchKey = async() => {
//         let response;

//         try {
//             response = await client.send(
//                 new GetSecretValueCommand({
//                 SecretId: secret_name,
//                 VersionStage: "AWSCURRENT", // VersionStage defaults to AWSCURRENT if unspecified
//                 })
//             );
//         } catch (error) {
//             console.log("error from fetching key: ", error)
//         // For a list of exceptions thrown, see
//         // https://docs.aws.amazon.com/secretsmanager/latest/apireference/API_GetSecretValue.html
//             throw error;
//         }

//     const secret = response.SecretString;
//     console.log("Fetched key: ", secret)
// }
    
    // const fetchSecret = () => {
    //     const secretName = 'Magento_Admin_Oauth';
    //     const awsConfig = {
    //         region: 'ap-south-1', // Replace with your AWS region (e.g., 'us-east-1')
    //         accessKeyId: 'AKIAU7OHNFO5ETHB47OD', // Replace with your access key ID
    //         secretAccessKey: 'VTkSVgUDHYi2/vvZKPnB/DS0Bl0m2QrTb+0Vu7Tj', // Replace with your secret access key
    //     };
    //     AWS.config.update(awsConfig)
    //     const secretsManager = new AWS.SecretsManager();
    //     secretsManager.getSecretValue({ SecretId: secretName }, (err, data) => {
    //         console.log('Service is running')
    //         if (err) {
    //             console.error('Error retrieving the secret:', err);
    //         } else {
    //             const secret = JSON.parse(data?.SecretString);
    //             setFetchedSecretKey(true)
    //             setSecretKey(secret)
    //             console.log("secret key: ", secret)
    //         }
    //     })
    // }

    const requestData = {
        method: 'GET', // Or any other HTTP method your API requires
        url: url,
    };

    usePageDurationTracker("Orders");

    const requestHeaders = oauth.toHeader(oauth.authorize(requestData, { key: secret?.accessToken, secret: secret?.accessTokenSecret }));
    const fetchOrders = () => {
        setLoading(true)
        axios({method: 'get',  url: url,  headers: requestHeaders})
        .then(response => {
            console.log('Response:', response.data);
            const orderedItems = response?.data?.items.filter((item) => {
                return item.product_type !== 'configurable';
            });
            const configurableProductPrices = {};
            response?.data?.items.forEach((item) => {
                if (item.product_type === 'simple' && item.parent_item) {
                    const parentItemId = item.parent_item.item_id;
                    configurableProductPrices[parentItemId] = item.parent_item.price;
                }
            });
            orderedItems?.forEach((item) => {
                item.price = configurableProductPrices[item.parent_item_id];
            })
            setAllOrders(orderedItems)
            setOrders(orderedItems?.slice(-5))
            setLoading(false)
        })
        .catch(error => {
            console.error('Error:', error);
            setLoading(false)
        });
    }
//     const fetchOrders = () => {
//   setLoading(true);
//   axios({ method: 'get', url: url, headers: requestHeaders })
//     .then(response => {
//       console.log('Response:', response.data);

//       // Check if the items are nested within extension_attributes field
//       const itemsArray = response?.data?.extension_attributes?.items || response?.data?.items;

//       const orderedItems = itemsArray.filter((item) => {
//         return item.product_type === 'simple' && item.qty_ordered > 0;
//       });

//       console.log('Ordered Items:', orderedItems);

//       // Create a lookup object to store the configurable product prices
//       const configurableProductPrices = {};
//       itemsArray.forEach((item) => {
//         if (!item.parent_item && item.product_type === 'configurable') {
//           const parentItemId = item.item_id;
//           configurableProductPrices[parentItemId] = item.price;
//         }
//       });

//       console.log('Configurable Product Prices:', configurableProductPrices);

//       orderedItems.forEach((item) => {
//         item.price = configurableProductPrices[item.item_id];
//       });

//       console.log('Final Ordered Items:', orderedItems);

//       setOrders(orderedItems);
//       setLoading(false);
//     })
//     .catch(error => {
//       console.error('Error:', error);
//       setLoading(false);
//     });
// };

    
    useEffect(() => {
        console.log(`Log in orders of secret: ${secret?.accessToken} and ${secret?.accessTokenSecret}`)
        if (secret?.accessToken) {
            fetchOrders()
        }
    },[secret])

    useEffect(() => {
        if (!localStorage?.getItem("products")) {
            fetchProducts()
        }  
    },[])



    // useEffect(() => {
    //     fetchSecret()
    //     if (fetchedSecretKey) {
    //         console.log(`Secret key: consumer key: ${secretKey?.ConsumerKey}`)
    //         fetchOrders()
    //     };
        
        
    // }, [fetchedSecretKey])
    


  return (
      <div className="orders-container w-full flex flex-col md:p-4 items-center justify-center font-outfit min-h-[550px] md:gap-10 gap-5">
          {loading ? (
              <div className="loading-container p-4 flex justify-center items-center w-full">
                  <svg aria-hidden="true" className="w-5 h-5 mr-2 text-gray-200 animate-spin  fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                  </svg>
              </div>
          ) : (
            <>
                <div className="md:w-4/5 w-full p-2"><p className="md:text-2xl text-xl font-medium">Order History</p></div>
                <div className="order-map-container md:w-4/5 w-full flex flex-col items-center justify-start gap-4 p-2">
                {/* Order History map starts here*/}
                          
                {orders?.length > 0 ? (
                    
                        orders.reverse()?.map((order) => {
                            return (
                                <OrderCard order={order}/>
                        //     <div className="order-container w-full" key={order?.entity_id}>
                        //             <div className="order-info-container w-full flex items-center justify-between border-b border-gray-300 py-3">
                        //                 <div className="order-info flex items-center justify-start gap-10 w-3/4">
                        //                     <p className="flex gap-2 items-baseline hover:underline cursor-pointer" onClick={() => navigate(`/order/${order?.entity_id}`, { state: order })}><span className="text-gray-500 text-sm">OrderID: </span><span className="font-medium">#{order?.increment_id}</span></p>
                        //                     <p className="flex gap-2 items-baseline"><span className="text-gray-500 text-sm">Date: </span><span className="font-medium">{order?.created_at?.split(" ")[0]}</span></p>
                        //                     <p className="flex gap-2 items-baseline"><span className="text-gray-500 text-sm">Order status: </span><span className="font-medium capitalize">{order?.status}</span></p>
                        //                 </div>
                        //                 <div className="flex">
                        //                     <button className="p-2 px-4 rounded-md bg-gray-100 hover:bg-gray-200 delay-400 text-sm font-medium">View Invoice</button>
                        //                 </div>
                        //             </div>
                        //             <div className="flex flex-col gap-4">
                        //             {order?.items?.map((product, index) => {
                        //             if (product?.product_type !== "configurable") {
                        //                     return (
                        //                     <div className="w-full py-6 flex justify-between items-start" key={index}>
                        //                         <div className="flex gap-3">
                        //                             <div>
                        //                                 <img className="rounded-md object-contain w-[100px] h-[100px]" src={default_image} alt="prod-img" />
                        //                             </div>
                        //                             <div className="flex flex-col gap-3">
                        //                                 <p className="font-medium">{product?.name}</p>
                        //                                 <p className=""><span className="text-gray-400 font-medium">Qty: </span>{product?.qty_ordered}</p>
                        //                                 <p className="capitalize">{product?.product_type}</p>
                        //                             </div>
                        //                         </div>
                        //                         <div  className="font-medium">${product?.row_total === 0 ? product?.parent_item?.base_row_total : product?.row_total }</div>
                        //                     </div>
                        //                 )
                        //             } else {
                        //                 return null
                        //             }
                                       
                        //         })}      
                        // </div>
                                    
                        //     </div>
                        )
                    })     
                    
                    ) : (<p>You haven't placed orders yet!</p>)
                }
                      </div>
                    {(allOrders?.length > 5 && orders?.length !== allOrders?.length) && (
                        <div className="w-full flex justify-center items-center">
                            <button onClick={()=>setOrders(allOrders)}>
                                Load More Orders
                            </button>
                        </div>
                    )}
            </>
        
          )
          }
    </div>
  )
}

export default Orders