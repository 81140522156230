import { useEffect } from "react";
import rudderAnalytics from "../../rudderstack";

const usePageDurationTracker = (pageName) => {
  useEffect(() => {
    const startTime = Date.now();

    return () => {
      const endTime = Date.now();
      const duration = (endTime - startTime) / 1000; 
      rudderAnalytics.track("Page Duration", {
        page: pageName,
        duration: duration,
      });
    };
  }, [pageName]);
};

export default usePageDurationTracker;
