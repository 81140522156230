import React, { useState } from 'react'
import {AiOutlineDown} from 'react-icons/ai'

const Accordion = ({ data }) => {
    const [show, setShow] = useState(false)
  return (
    <div
      id="accordion-flush"
      data-accordion="open"
      data-active-classes="bg-white dark:bg-gray-900 text-gray-900 dark:text-white"
      data-inactive-classes="text-gray-500 dark:text-gray-400"
    >
      <h2 id="accordion-flush-heading-1" onClick={() => setShow(!show)}>
        <button
          type="button"
          className="flex items-center justify-between w-full py-5 font-medium text-left  border-b border-gray-200 dark:border-gray-700 "
          data-accordion-target="#accordion-flush-body-1"
          aria-expanded="true"
          aria-controls="accordion-flush-body-1"
        >
          <span>{data?.topic}</span>
          <p className={`${show && "rotate-180"} duration-500 ease-in-out`}>
            <AiOutlineDown />
          </p>
        </button>
      </h2>
      <div
        id="accordion-flush-body-1"
        className={`${
          show ? "opacity-100 h-auto py-1" : "opacity-0 h-0"
        } overflow-hidden transition-all duration-500 ease-in-out`}
        aria-labelledby="accordion-flush-heading-1"
      >
        <div className="py-5">
          <p className="mb-2 text-gray-500">{data?.info}</p>
        </div>
      </div>
    </div>
  );
}

export default Accordion