import React, {useEffect, useState} from 'react'
import { CartState } from '../../context/Context'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom';


const ProfileInfo = () => {
    const cookies = new Cookies();
    const token = cookies?.get("auth_token")
    const navigate = useNavigate();
    const { state: { userData }, dispatch } = CartState();
    const [editPersonal, setEditPersonal] = useState(false)
    const [editEmail, setEditEmail] = useState(false)
    const [formData, setFormData] = useState(userData)
    const [firstname, setFirstname] = useState(userData?.firstname)
    const [lastname, setLastname] = useState(userData?.lastname)
    const [email, setEmail] = useState(userData?.email)

    const req_headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }


    const userInfoUpdate = () => {
        console.log('User Info Update ran')
        if (userData?.firstname === firstname && userData?.lastname === lastname) {
            toast.dark(`🚫No action taken. No change in values noted.`)
            console.log('User Info Update ran, if condition')
        } else {
            console.log('User Info Update ran, else condition')
            handleUpdate()
        }
    }

    const userEmailUpdate = () => {
        if (userData?.email === email) {
            toast.dark(`🚫No action taken. No change in value noted.`)
        } else {
            handleUpdate()
        }
    }

    const handleUpdate = () => {
        axios.put(`${process.env.REACT_APP_API_URL}/rest/V1/customers/me`, { customer: { firstname: firstname, lastname: lastname, email: email } }, { headers: req_headers })
        .then((response) => {
            dispatch({
                type: "UPDATE_USER_DATA",
                payload: {
                    data: response?.data,
                },
            })    
            setEditPersonal(false)
            toast.dark(`✅ Updated Successfully!`)
        })
        .catch((error) => {
            console.log("Error received: ", error?.response?.data?.message)    
        })
    }


    const faq_list = [
        {
            question: "What happens when I update my email address (or mobile number)?",
            answer: "Your login email id (or mobile number) changes, likewise. You'll receive all your account related communication on your updated email address (or mobile number)."
        },
        {
            question: "When will my account be updated with the new email address (or mobile number)?",
            answer: "It happens as soon as you confirm the verification code sent to your email (or mobile) and save the changes."
        },
        {
            question: "What happens to my existing account when I update my email address (or mobile number)?",
            answer: "Updating your email address (or mobile number) doesn't invalidate your account. Your account remains fully functional. You'll continue seeing your Order history, saved information and personal details."
        }
    ]

    useEffect(() => {
        if (cookies?.get("auth_token") === undefined) {
            alert("Please login to view this page")
            navigate("/login")
        }
    },[])

  return (
    <div className='prf-info-container w-full p-4 gap-6 flex flex-col pb-8'>
          <div className='pi-top-container w-full flex flex-col gap-6'>
              {/* <!--Personal Info Update--> */}  
            <div className='flex gap-8 justify-start items-center'>
                <p className='font-semibold'>Personal Information</p>
                  <button className='text-blue-700' onClick={() => {
                    setEditPersonal(!editPersonal)
                    setEditEmail(false)
                  }}>{editPersonal ? "Cancel" : "Edit"}</button>
           </div>
            <form className="flex gap-4 items-center justify-start" onSubmit={(e) => {
                e?.preventDefault()
                // userInfoUpdate()
              }}>
                  <div className={`border border-gray-400 p-1 px-2 w-[200px] h-[50px] rounded-sm flex flex-col ${editPersonal ? "justify-start": "justify-center bg-gray-100"}`}>
                      {editPersonal && <label htmlFor="firstname" className='text-xs text-gray-400'>First Name</label>}
                      <input type="text" name="firstname" id="firstname" onChange={(e)=>setFirstname(e?.target?.value)} value={firstname} className={`${editPersonal ? "bg-white" : "bg-gray-100"} outline-none`} disabled={!editPersonal}/>  
                  </div>  
                  <div className={`border border-gray-400 p-1 px-2 w-[200px] h-[50px] rounded-sm flex flex-col ${editPersonal ? "justify-start": "justify-center bg-gray-100"}`}>
                      {editPersonal && <label htmlFor="lastname" className='text-xs text-gray-400'>Last Name</label>}
                      <input type="text" name="lastname" id="lastname" onChange={(e)=>setLastname(e?.target?.value)} value={lastname} className={`${editPersonal ? "bg-white" : "bg-gray-100"} outline-none`} disabled={!editPersonal} />  
                  </div> 
                  {editPersonal && <div className='h-[50px] flex items-end'>
                      <label htmlFor="submit" className='hidden'>Submit</label>
                      <button id="submit" className='h-[50px] bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-sm w-[100px]' onClick={()=>{userInfoUpdate()}}>Save</button>
                  </div>
                  }
            </form>  
            {/* <!--Email Address Update--> */}  
            <div className='flex gap-8 justify-start items-center'>
                <p className='font-semibold'>Email Address</p>
                  <button className='text-blue-700' onClick={() => {
                      setEditEmail(!editEmail)
                      setEditPersonal(false)
                  }}>{editEmail ? "Cancel" : "Edit"}</button>
           </div>
            <form className="flex gap-4 items-center justify-start" onSubmit={(e) => {
                e.preventDefault()
                // handleLogin()
              }}>
                  <div className={`border border-gray-400 p-1 px-2 w-[280px] h-[50px] rounded-sm flex flex-col ${editEmail ? "justify-start": "justify-center bg-gray-100"}`}>
                      {editEmail && <label htmlFor="firstname" className='text-xs text-gray-400'>Email Address</label>}
                      <input type="text" name="firstname" id="firstname" onChange={(e)=>setEmail(e?.target?.value)} value={email} className={`${editEmail ? "bg-white" : "bg-gray-100"} outline-none`} disabled={!editEmail}/>  
                  </div>  
                  {editEmail && <div className='h-[50px] flex items-end'>
                      <label htmlFor="submit" className='hidden'>Submit</label>
                      <button id="submit" className='h-[50px] bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-sm w-[100px]' onClick={()=>{userEmailUpdate()}}>Save</button>
                  </div>
                  }
              </form>  
           
        </div>
        <div className='flex flex-col gap-3 justify-center items-start'>
              <p className='font-semibold'>FAQ</p>
              <div className='w-full flex flex-col justify-start items-start gap-4'>
                  {
                  faq_list?.map((item, index) => (
                      <div key={index} className='flex flex-col gap-2'>
                          <p className='question-text font-medium'>{item?.question}</p>
                          <p className='text-gray-700'>{item?.answer}</p>
                      </div>
                  ))
              }
              </div>
          </div>
        <div className='font-outfit text-sm '>
            <ToastContainer position='bottom-left' autoClose={4000} type="dark" />
        </div>
    </div>
  )
}

export default ProfileInfo