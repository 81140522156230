import axios from "axios";
import React, { useEffect, useState } from "react";
import ProductCard from "../ProductCard/ProductCard";
import { useNavigate } from "react-router-dom";
import { CartState } from "../../context/Context";

const Product = () => {
    const navigate = useNavigate();
    const { state: { compare }, dispatch } = CartState();
    const [productList, setProductList] = useState([]);
    const [cartId, setCartId] = useState(null)
    const header_token = { "username": "admin", "password": "Admin@12345" }
    
    // const token = window?.sessionStorage.getItem("auth_token")
    

    const guestCartId = () => {
        const getCart_headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${header_token}`
        }
        axios.post(`${process.env.REACT_APP_API_URL}/rest/V1/guest-carts`, { headers: getCart_headers })
        .then((response) => {
            setCartId(response?.data)
        })
        .catch((error) => {
            console.log("error from getCartID: ", error)
        })
    }

    //Example for Request Headers with Bearer Token

    // const createUserCart = () => {
    //     console.log("cart creation started")
    //     const cartHeaders = {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${token}`
    //     };

    //     axios.post("http://65.0.150.104/rest/V1/carts/mine", null, {
    //         headers: cartHeaders
    //     })
    //     .then((response) => {
    //         console.log("Response from creating an active cart: ", response?.data);
    //         window.localStorage.setItem("cart_id", response?.data);
    //     })
    //     .catch((error) => {
    //         console.log("Error while fetching active cart: ", error?.response?.data?.message);
    //     });
    // };

    const fetchProducts = (updateProducts) => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/rest/V1/products?searchCriteria[pageSize]=10`)
            .then((res) => {
                setProductList(res?.data?.items);
                if (!localStorage?.getItem("products")) {
                    localStorage?.setItem("products", JSON.stringify(res?.data?.items))
                }
            })
            .catch((err) => {
                console.log("Error while fetching products: ", err)
            })
    }

    useEffect(() => {
        fetchProducts();
        // if(!localStorage?.getItem("cart_id")) createUserCart();
    }, [])

    return (
        <div className="w-full flex flex-col items-start justify-center md:gap-5 font-outfit min-h-[450px]">
            <div className="flex flex-col md:flex-row justify-start w-full gap-4">
                <p className='md:text-2xl text-lg md:p-5 px-3'>PRODUCT RECOMMENDATIONS</p>
                {compare?.length > 1 && <button className="text-blue-500" onClick={()=>navigate('/compare-product/')}>Compare Products</button>}
                
            </div>
            <div className="flex flex-wrap md:gap-4 gap-2 gap-y-3 p-5 justify-center">
                {
                    productList?.length !== 0 &&
                    productList.map((data,index) => {
                        return (
                            <ProductCard data={data} key={index} />
                        )
                    })
                }
            </div>
        </div>
    )
}
export default Product;