import React, {useState, useEffect} from 'react'
import { useNavigate, useLocation, useParams  } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import OAuth from 'oauth-1.0a';
import { HmacSHA256, enc } from 'crypto-js';
import { CartState } from '../../context/Context';
import { OrderItemCard } from '../Orders/Orders';
import usePageDurationTracker from '../../components/Hooks/usePageDurationTracker';



const OrderDetails = () => {
    const navigate = useNavigate()
    const {state: {secret}, dispatch} = CartState()
    const location = useLocation()
    const {orderId} = useParams()
    const [data, setData] = useState(location?.state)
    const [items, setItems] = useState(data?.items)
    const default_image = "https://cdn.rareblocks.xyz/collection/clarity-ecommerce/images/order-confirmation/3/product-1.png"
    const shipping_address = data?.extension_attributes?.shipping_assignments?.[0]?.shipping?.address
    const [loading, setLoading] = useState(false)
    const [cancelLoading, setCancelLoading] = useState(false)
    // const consumerKey = 'e17nkc2s9rshf6ugsgz8edu95d6wjdg3';
    // const consumerSecret = 'ezyfqs5t1rru7ilcm3xoxzcutezbf6sf';
    // const accessToken = 'u48ubgk55kld1airlury0nv38qe84qy3';
    // const accessTokenSecret = '40wgxau86ufg9h6vrbnpuus86pwq10tu';
    const cancel_url = `${process.env.REACT_APP_API_URL}/rest/V1/orders/${data?.entity_id}/cancel`;

    const oauth = OAuth({
        consumer: {
            key: secret?.consumerKey,
            secret: secret?.consumerSecret,
        },
        signature_method: 'HMAC-SHA256',
        hash_function(base_string, key) {
            return HmacSHA256(base_string, key).toString(enc.Base64); // Use HmacSHA256 from crypto-js
        },
    });

    const requestData = {
        method: 'POST', 
        url: cancel_url,
    }
    const requestHeaders = oauth.toHeader(oauth.authorize(requestData, { key: secret?.accessToken, secret: secret?.accessTokenSecret }));
    
    const cancelOrder = () => {
        setCancelLoading(true)
        axios({method: 'POST',  url: cancel_url,  headers: requestHeaders})
        .then(response => {
            if(response?.data === true) toast.dark(`✅ Order cancelled successfully`, { closeOnClick: true })
            console.log('Response:', response.data);
            setCancelLoading(false)
        })
        .catch(error => {
            toast.dark(`❌ Error cancelling order. Please try again.`, { closeOnClick: true })
            console.error('Error:', error);
            setCancelLoading(false)
        });
    }

    const fetchOrderDetails = () => {
        setLoading(true)
        const orderUrl = `${process.env.REACT_APP_API_URL}/rest/default/V1/orders/${orderId}`;
        const requestData = {
            method: 'GET', // Or any other HTTP method your API requires
            url: orderUrl
        };
        const requestHeaders = oauth.toHeader(oauth.authorize(requestData, { key: secret?.accessToken, secret: secret?.accessTokenSecret }));
        axios({ method: 'get', url: orderUrl, headers: requestHeaders })
        .then((response) => {
            console.log("response from fetching single order: ", response?.data)  
            setData(response?.data)
            const orderedItems = response?.data?.items.filter((item) => {
                return item.product_type !== 'configurable';
            })
            console.log("Ordered items: ", orderedItems)
            setItems(orderedItems)
            setTimeout(() => {
                setLoading(false)
            },1500)
            

        })
        .catch((error) => {
            console.log("error while fetching order: ", error?.response?.data)
            setLoading(false)
        })
    }

    usePageDurationTracker("Order Details");

    useEffect(() => {
        console.log("secret log: ", secret)
        if (!data) {
            console.log("secret log: ", secret)
            fetchOrderDetails()
        }
    },[secret])



    return (
        <>
            {loading ? (
                <div className="loading-container p-4 flex justify-center items-center w-full h-screen">
                    <svg aria-hidden="true" className="w-5 h-5 mr-2 text-gray-200 animate-spin  fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    </svg>
                </div>
            ) : (
                    <div className="success-container w-full flex flex-col items-center justify-start" >
                <div className="w-full flex flex-col font-outfit justify-start items-center my-5">
                <div className="md:w-3/4 w-full p-2 flex items-center justify-between">
                <p className="md:text-2xl text-lg font-medium">Order: #{data?.increment_id}</p>
                {/* <button className="p-2 px-4 rounded-md bg-gray-100 hover:bg-gray-200 delay-400 text-sm font-medium">View Invoice</button> */}
            </div>
                <div className="md:w-3/4 w-full rounded-md flex flex-col items-center justify-start md:p-4 border gap-5">
                    <div className="border-b-2 w-11/12 flex justify-between items-start md:p-3 py-4">
                        <p className="flex gap-2"><span className="text-gray-400">Order Status:</span> <span className="capitalize">{data?.status}</span></p>
                    </div>
                    <div className="border-b-2 w-11/12 flex justify-between items-start md:p-3 md:py-4 py-2">
                        <div className="gap-5 flex flex-col">
                            <div>
                                <p className="text-gray-400 text-sm font-medium">SHIPPING ADDRESS</p>
                           </div>
                            <div className="leading-relaxed">
                                <p>{shipping_address?.firstname+ ' ' + shipping_address?.lastname}</p>
                                <p>{shipping_address?.street[0]}, {shipping_address?.city},<br /> {shipping_address?.region} {shipping_address?.postcode}, {shipping_address?.country_id}</p>
                            </div>           
                        </div>
                        <div className="gap-5 flex flex-col">
                            <div>
                                <p className="text-gray-400 text-sm font-medium">PAYMENT INFO</p>
                           </div>
                            <div className="leading-relaxed">
                                <p>{data?.extension_attributes?.payment_additional_info?.[0]?.value}</p>
                            </div>           
                        </div>
                    </div>
                    <div className="w-11/12 flex flex-col gap-5 ">
                        <div>
                            <p className="text-gray-400 text-sm font-medium">ORDER ITEMS</p>           
                        </div>
                        <div className="flex flex-col gap-4 border-b-2">
                            {items?.map((product, index) => {
                                if (product?.product_type !== "configurable") { 
                                    return (
                                    <OrderItemCard product={product}/>
                                ) 
                                } else {
                                    return null
                                }
                                   
                            })}      
                        </div>
                        <div className="py-5 w-full flex flex-col gap-4">
                            <div className="total-calculation flex flex-col gap-2 w-full">
                                <div className="flex w-full justify-between items-center font-medium">
                                <p className="text-gray-400 text-sm font-medium">Subtotal</p>
                                <p>${data?.base_subtotal}</p>
                            </div>
                            <div className="flex w-full justify-between items-center font-medium">
                                <p className="text-gray-400 text-sm font-medium">Shipping & Handling</p>
                                <p>${data?.base_shipping_incl_tax}</p>
                            </div>
                            <div className="flex w-full justify-between items-center font-medium">
                                <p className="text-gray-400 text-sm font-medium">Discount</p>
                                <p className='text-green-500'>-${Math.abs(data?.discount_amount)}</p>
                            </div>
                            </div>
                            
                            <div className="w-full">

                            </div>
                            <div className="flex w-full justify-between items-center font-medium">
                                <p className="text-lg font-medium">Total</p>
                                <p className="text-lg font-medium">${data?.grand_total}</p>
                           </div>
                           
                        </div>       
                    </div>  
                </div>      
                  
            </div>
            <div className="md:w-3/4 w-full flex justify-between p-2">
                <button className="bg-white text-black py-2 rounded-md text-xs cursor-pointer px-3 border-[1px] md:w-[111px] border-black" onClick={()=>navigate("/orders")}>GO BACK</button>
                <div className='w-auto flex items-center gap-2'>
                    <button className="bg-black text-white py-2 rounded-md text-xs cursor-pointer px-3">TRACK ORDER</button>
                    <button className="bg-white text-black py-2 flex items-center justify-center rounded-md text-xs cursor-pointer px-3 border-[1px] md:w-[111px] border-black disabled:cursor-default disabled:text-gray-400" disabled={data?.status === "canceled"} onClick={()=>{cancelOrder()}}>
                        {cancelLoading ? (
                            <svg aria-hidden="true" className="w-5 h-5 mr-2 text-gray-200 animate-spin  fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                        ) : (
                            "CANCEL ORDER"
                        )}
                    </button> 
                </div>
                
                        </div>
                        </div>
            )}

            <div className='font-outfit text-sm '>
                <ToastContainer position='bottom-left' autoClose={4000} type="dark"/>
            </div>
            </>
    )
}

export default OrderDetails