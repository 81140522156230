import { useState } from "react"
import { BsSearch } from 'react-icons/bs'
import logo from "./newsletter.png"
import {CgMail} from 'react-icons/cg'
const Newsletter = () => {
    const [isvisible, setVisible] = useState(false)
    return (
        <div className='w-full px-12'>
            <button type="button" className="relative rounded-full bg-gray-800 p-1 text-gray-400"
                onClick={() => setVisible(!isvisible)}
            >
                Click

            </button>
            {
                isvisible ? (
                    <div className="fixed inset-0 bg-white bg-opacity-25 backdrop-blur-sm flex justify-center items-center">
                            <div className="m-auto w-full sm:w-[50%] flex flex-col p-5 bg-gray-200 justify-center items-center ">
                                <button className="text-white text-lg place-self-end w-[35px] h-[35px] rounded-md bg-gray-700 font-medium" onClick={() => setVisible(!isvisible)}> X</button>
                               
                                <h1 className="text-2xl p-2 font-semibold">Subscribe to</h1>
                                <h1 className="text-3xl p-2 font-semibold">Our Newsletter</h1>
                                <p className="text-sm p-2 font-semibold">Subscribe to our Newsletter and stay updated</p>
                               
                               <img src={logo} className="w-44 h-44 p-3 justify-center" />
                                 <div className="flex px-2 m-12 py-1 my-3  sm:w-2/4 overflow-hidden bg-white  items-center gap-2 border justify-between border-slate-600 rounded-md">
                                    <div className="flex items-center justify-start w-4/5">
                                        <CgMail color="#141414" size={20} />
                                        <input type="text"
                                            className=" border-none  px-2 outline-none py-2 text-sm md:text-base	"
                                            placeholder="Enter your email address"
                                           
                                        />
                                    </div>
                                    
                                </div>
                                <button 
                                    className="bg-black hover:bg-gray-900 w-2/4  text-white text-sm font-medium px-3 
                                    py-2  mb-20 cursor-pointer outline-none rounded-sm">
                                        Submit
                                    </button>
                                        </div>
                            </div>
                ) : ""
            }
        </div>
    )
}
export default Newsletter