
const StarRating = ({ rating, setRating }) => {

  return (
    <div className="flex">
      {[...Array(5)].map((_, index) => (
        <span
          key={index}
          className={`cursor-pointer text-2xl ${
            index < rating ? "text-yellow-500" : "text-gray-400"
          }`}
          onClick={() => setRating(index + 1)}
        >
          &#9733;
        </span>
      ))}
    </div>
  );
};

export default StarRating;